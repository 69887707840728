import { observer } from "mobx-react";
import React, { useState } from "react";
import { Button, Stack, Modal, Menu, rem } from "@mantine/core";
import { IconCopy, IconLogout, IconCaretDown } from "@tabler/icons-react";
import _ from "lodash";
import { isMobileOnly } from "react-device-detect";
import { ExplorerWorkspace } from "../../stores/ExplorerWorkspace";
import { NOSTR_PLATFORM_NAMES } from "../../helpers/nostr";

const NostrLoginButton = observer(
  ({ explorerWorkspace }: { explorerWorkspace: ExplorerWorkspace }) => {
    const [modalOpened, setModalOpened] = useState(false);

    const handleLogin = () => {
      setModalOpened(true);
    };

    const handleLogout = () => {
      explorerWorkspace.setNostrAlsoToStorage(null, null);
      //window.location.reload();
    };

    const closeModal = () => {
      setModalOpened(false);
    };

    const truncatedKey = (key) => {
      return `${key.slice(0, 8)}...${key.slice(-8)}`;
    };

    return (
      <div>
        {explorerWorkspace.nostrPublicKey === null ? (
          <Button
            variant="default"
            color="gray"
            leftSection={
              <img
                src="https://d3u5cu1kr5hxm.cloudfront.net/images/nostr-injected-logo.svg"
                alt="custom icon"
                style={{ width: 14, height: 14 }}
              />
            }
            onClick={handleLogin}
          >
            Login with Nostr
          </Button>
        ) : (
          <Menu shadow="md" width={300}>
            <Menu.Target>
              <Button
                variant="default"
                color="gray"
                rightSection={<IconCaretDown />}
              >
                {truncatedKey(explorerWorkspace.nostrPublicKey)}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconCopy />}
                onClick={() =>
                  navigator.clipboard.writeText(
                    explorerWorkspace.nostrPublicKey
                  )
                }
              >
                copy nostr address
              </Menu.Item>
              <Menu.Item leftSection={<IconLogout />} onClick={handleLogout}>
                disconnect nostr
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}

        <Modal
          opened={modalOpened}
          onClose={closeModal}
          title="Login With Nostr Browser Extension"
        >
          <Stack>
            <Button
              variant="default"
              color="gray"
              leftSection={
                <img
                  src="https://d3u5cu1kr5hxm.cloudfront.net/images/nostr-okx-logo.png"
                  alt="custom icon"
                  style={{ width: 14, height: 14 }}
                />
              }
              onClick={() => {
                explorerWorkspace.handleLoginToNostrButton(
                  NOSTR_PLATFORM_NAMES.OKX
                );
                closeModal();
              }}
            >
              OKX Wallet
            </Button>
            <Button
              variant="default"
              color="gray"
              leftSection={
                <img
                  src="https://d3u5cu1kr5hxm.cloudfront.net/images/nostr-tokenpocket-logo.png"
                  alt="custom icon"
                  style={{ width: 14, height: 14 }}
                />
              }
              onClick={() => {
                explorerWorkspace.handleLoginToNostrButton(
                  NOSTR_PLATFORM_NAMES.TOKENPOCKET
                );
                closeModal();
              }}
            >
              TokenPocket
            </Button>
            <Button
              variant="default"
              color="gray"
              leftSection={
                <img
                  src="https://d3u5cu1kr5hxm.cloudfront.net/images/nostr-onekey-logo.png"
                  alt="custom icon"
                  style={{ width: 14, height: 14 }}
                />
              }
              onClick={() => {
                explorerWorkspace.handleLoginToNostrButton(
                  NOSTR_PLATFORM_NAMES.ONEKEY
                );
                closeModal();
              }}
            >
              OneKey
            </Button>

            <Button
              variant="default"
              color="gray"
              leftSection={
                <img
                  src="https://d3u5cu1kr5hxm.cloudfront.net/images/nostr-alby-logo.png"
                  alt="custom icon"
                  style={{ width: 14, height: 14 }}
                />
              }
              onClick={() => {
                explorerWorkspace.handleLoginToNostrButton(
                  NOSTR_PLATFORM_NAMES.ALBY
                );
                closeModal();
              }}
            >
              Alby
            </Button>
            <Button
              variant="default"
              color="gray"
              leftSection={
                <img
                  src="https://d3u5cu1kr5hxm.cloudfront.net/images/nostr-injected-logo.svg"
                  alt="custom icon"
                  style={{ width: 14, height: 14 }}
                />
              }
              onClick={() => {
                explorerWorkspace.handleLoginToNostrButton(
                  NOSTR_PLATFORM_NAMES.GENERIC
                );
                closeModal();
              }}
            >
              Any Nostr Extension
            </Button>
          </Stack>
        </Modal>
      </div>
    );
  }
);

export default NostrLoginButton;
