import { Container, Group, Anchor, Stack } from "@mantine/core";
import { MantineLogo } from "@mantinex/mantine-logo";
import classes from "./FooterSimple.module.css";
import React, { useState } from "react";
import LogoAnchor from "./Logo";
const links = [
  { link: "/privacy_and_terms", label: "Privacy & Terms" },
  { link: "/contact", label: "Contact Us" },
  { link: "https://docs.megalithic.me", label: "Lightning Docs" },
  { link: "/taproot_assets", label: "Taproot Assets Exchange" },
  { link: "/taproot_assets_explorer", label: "Taproot Assets Explorer" },
  { link: "/power_list", label: "Power List" },
];
const handleItemClick = (link) => {
  window.location.href = link.link; // Navigate to the clicked URL
};

export function FooterSimple() {
  const items = links.map((link) => (
    <Anchor<"a">
      c="dimmed"
      key={link.label}
      href={link.link}
      onClick={(event) => {
        event.preventDefault();
        handleItemClick(link);
      }}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Stack>
          <LogoAnchor showImage={false} />
          <Group className={classes.links}>{items}</Group>
        </Stack>
      </Container>
    </div>
  );
}
