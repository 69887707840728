import React from "react";
import { Box, Tooltip } from "@mantine/core";
import { Image } from "@mantine/core";
import {
  VGFlag,
  FRFlag,
  SVFlag,
  USFlag,
  BVFlag,
  ILFlag,
  CAFlag,
  SCFlag,
  CWFlag,
  DEFlag,
  SGFlag,
  NLFlag,
  SEFlag,
  VNFlag,
  JPFlag,
  LTFlag,
  AUFlag,
  CZFlag,
  FIFlag,
  AEFlag,
} from "mantine-flagpack";

interface MyComponentProps {
  countryCode: string;
}

const flagMap: {
  [key: string]: { labelText: string; Component: React.FC<any> };
} = {
  VG: { labelText: "British Virgin Islands", Component: VGFlag },
  FR: { labelText: "France", Component: FRFlag },
  SV: { labelText: "El Salvador", Component: SVFlag },
  US: { labelText: "United States", Component: USFlag },
  BV: { labelText: "Bouvet Island", Component: BVFlag },
  IL: { labelText: "Israel", Component: ILFlag },
  CA: { labelText: "Canada", Component: CAFlag },
  SC: { labelText: "Seychelles", Component: SCFlag },
  CW: { labelText: "Curaçao", Component: CWFlag },
  DE: { labelText: "Germany", Component: DEFlag },
  SG: { labelText: "Singapore", Component: SGFlag },
  NL: { labelText: "Netherlands", Component: NLFlag },
  SE: { labelText: "Sweden", Component: SEFlag },
  VN: { labelText: "Vietnam", Component: VNFlag },
  JP: { labelText: "Japan", Component: JPFlag },
  LT: { labelText: "Lithuania", Component: LTFlag },
  AU: { labelText: "Australia", Component: AUFlag },
  CZ: { labelText: "Czech Republic", Component: CZFlag },
  FI: { labelText: "Finland", Component: FIFlag },
  AE: { labelText: "United Arab Emirates", Component: AEFlag },
};

const Flag: React.FC<MyComponentProps> = ({ countryCode }) => {
  const { labelText, Component } = flagMap[countryCode] || {};

  return (
    <>
      {countryCode && labelText && Component && (
        <Tooltip label={labelText} color="grey">
          <Box>
            <Component w={20} />
          </Box>
        </Tooltip>
      )}

      {!countryCode && (
        // <Image
        //   h={16}
        //   w={20}
        //   fit="cover"
        //   style={{ opacity: 0.85 }}
        //   src="https://megalithic-public.s3.us-east-1.amazonaws.com/images/missing-flag-cropped.png"
        // />

        <Tooltip label={"Country Not Known"} color="grey">
          <Box>
            <>🌍</>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default Flag;
