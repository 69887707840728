import {
  Paper,
  Stack,
  Title,
  Anchor,
  Image,
  Text,
  List,
  Button,
  Divider,
  CopyButton,
  Textarea,
  Center,
  Box,
} from "@mantine/core";
import React from "react";
import { formatUnixTimestamp } from "../helpers/dateAndTime";
import _ from "lodash";
import { IconBrandTelegram, IconCopy } from "@tabler/icons-react";
import AssetTransaction from "./AssetTransaction";
interface Asset {
  assetName: string;
  imageUrlData: string | null;
  assetTextData: string | null;
  genesisTransaction: string;
  genesisTimestamp: number;
  assetId: string;
  assetType: "NORMAL" | "COLLECTIBLE";
  editorialDescription: string | null;
  supply: number;
  isBuyOrSell: "is_buy" | "is_sell";
  offerQuantity: number;
  offerPriceInSatoshis: number;
  price: string; // Added field
  formattedSupply: string; // Added field
  action: "Sell" | "Buy"; // Added field
  modifiedImageUrlData: string; // Added field
  note: string | null; // Added field
}

export interface AssetProps {
  asset: Asset;
}

const AssetDetail: React.FC<AssetProps> = ({ asset }) => {
  return (
    <Paper withBorder p="lg" pr="xl">
      <Stack>
        <Title order={2}> {asset.assetName}</Title>
        {asset.editorialDescription && (
          <Text>Megalith's note: {asset.editorialDescription}</Text>
        )}

        <div
          style={{
            //width: 240, // Width of the container
            height: 240, // Fixed height for all images
            overflow: "hidden", // Hide overflow to crop images
            display: "flex", // Use flex to align items
            alignItems: "center", // Center-align images vertically
            justifyContent: "left",
          }}
        >
          <img
            src={asset.modifiedImageUrlData}
            style={{
              width: "auto", // Auto width to maintain aspect ratio
              minHeight: "100%", // Minimum height to fill the container
              maxHeight: "100%", // Maximum height to fit the container
            }}
          />
        </div>

        <Textarea
          autosize
          radius="md"
          mt="8"
          mb="8"
          maw="98%"
          readOnly
          label="Asset ID"
          value={asset.assetId}
          //onFocus={(e) => e.currentTarget.select()}
        />
        <List>
          <List.Item>
            Genesis: {formatUnixTimestamp(asset.genesisTimestamp)}
          </List.Item>

          <List.Item>
            Transaction:{" "}
            <Anchor
              href={"https://mempool.space/tx/" + asset.genesisTransaction}
              target="_blank"
            >
              {_.truncate(asset.genesisTransaction, {
                length: 15,
              })}
            </Anchor>
          </List.Item>
          <List.Item>Supply: {asset.supply}</List.Item>
          <List.Item>
            Image data: {asset.imageUrlData == null ? "NO" : "YES"}
          </List.Item>
          <List.Item>
            Text data:{" "}
            {asset.assetTextData == null
              ? "NO"
              : '"' + asset.assetTextData + '"'}
          </List.Item>
        </List>
        <Divider />
        {asset.isBuyOrSell == "is_buy" && (
          <>
            <Title order={3}>Megalith is buying this asset</Title>
            <List>
              <List.Item>Wanted quantity: {asset.offerQuantity}</List.Item>
              <List.Item>
                Offer per asset:{" "}
                {(
                  asset.offerPriceInSatoshis / asset.offerQuantity
                ).toLocaleString()}{" "}
                satoshis
              </List.Item>
              <List.Item>
                Total offer: {asset.offerPriceInSatoshis.toLocaleString()}{" "}
                satoshis
              </List.Item>
            </List>
          </>
        )}
        {asset.isBuyOrSell == "is_sell" && (
          <>
            <Title order={3}>Megalith is selling this asset</Title>
            <List>
              <List.Item>Offered quantity: {asset.offerQuantity}</List.Item>
              <List.Item>
                Offer per asset:{" "}
                {(
                  asset.offerPriceInSatoshis / asset.offerQuantity
                ).toLocaleString()}{" "}
                satoshis
              </List.Item>
              <List.Item>
                Total offer: {asset.offerPriceInSatoshis.toLocaleString()}{" "}
                satoshis
              </List.Item>
            </List>
          </>
        )}
        <AssetTransaction asset={asset} />
      </Stack>
    </Paper>
  );
};

export default AssetDetail;
