import React from "react";
import { computed, observable } from "mobx";
import {
  registerRootStore,
  fromSnapshot,
  frozen,
  Frozen,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  onSnapshot,
  prop,
  Ref,
  undoMiddleware,
  _async,
  _await,
  idProp,
} from "mobx-keystone";
import { format } from "d3-format";
import { createContext } from "react";
import { convertSatoshisToUsd, formatSupply } from "../helpers/numbers";
import { NO_IMAGE_DATA_URL } from "../helpers/constants";
import _ from "lodash";

@model("megalithic/AssetWorkspace")
export class AssetWorkspace extends Model({
  id: idProp,
  hasLoaded: prop<boolean>(false),
  assetsWorkspaceDataFrozen: prop<Frozen<any> | undefined>(undefined),
}) {
  @modelAction
  setHasLoaded(hasLoaded: boolean) {
    this.hasLoaded = hasLoaded;
  }
  @modelAction
  hydrateDataFromPage() {
    const rootDiv = document.getElementById("root");
    const json = JSON.parse(rootDiv.dataset.hydrate);
    // console.log("here is the data from the page", json);
    this.assetsWorkspaceDataFrozen = frozen(json);

    this.setHasLoaded(true);
  }
  @computed
  get enhancedOffers() {
    const baseOffers = this.assetsWorkspaceDataFrozen.data.offers;
    const sortedOffers = _.sortBy(baseOffers, "rank");
    const enhancedOffers = sortedOffers.map((offer) => {
      const offerWithPrice = {
        ...offer,
        price:
          format(".2s")(offer.offerPriceInSatoshis) +
          " sats " +
          " (" +
          convertSatoshisToUsd(
            offer.offerPriceInSatoshis,
            this.assetsWorkspaceDataFrozen.data.single_setting_data
              .btc_usd_price
          ) +
          ")",
        formattedSupply: formatSupply(offer.supply),
        action: offer.isBuyOrSell == "is_buy" ? "Sell" : "Buy",
        modifiedImageUrlData: offer.imageUrlData
          ? offer.imageUrlData
          : NO_IMAGE_DATA_URL,
        note: offer.editorialDescription,
      };
      return offerWithPrice;
    });
    return enhancedOffers;
  }
}

export const runCreateAssetWorkspace = () => {
  const assetWorkspace = new AssetWorkspace({});
  registerRootStore(assetWorkspace);
  return assetWorkspace;
};

const createdAssetWorkspace = runCreateAssetWorkspace();
//console.log("created workspace", getSnapshot(createdWorkspace));
export const AssetWorkspaceContext = createContext<AssetWorkspace>(
  createdAssetWorkspace
);

export interface AssetWorkspaceInterface {
  AssetWorkspace: AssetWorkspace;
}
