import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { AssetWorkspace, AssetWorkspaceContext } from "./stores/AssetWorkspace";
import "@mantine/notifications/styles.css";
import {
  Center,
  Code,
  Stack,
  Title,
  Text,
  List,
  Anchor,
  Paper,
} from "@mantine/core";

import { isMobileOnly } from "react-device-detect";
import { IconBolt } from "@tabler/icons-react";
import AssetTable from "./components/AssetTable";
import AssetStats from "./components/AssetStats";
import KnownUniverses from "./components/partials/KnownUniverses";

const AssetWorkspaceContainer: React.FC = observer(() => {
  const assetWorkspace = useContext<AssetWorkspace>(AssetWorkspaceContext);

  useEffect(() => {
    //console.log("starting power list containers");
    assetWorkspace.hydrateDataFromPage();
    //console.log(assetWorkspace.assetsWorkspaceDataFrozen.data);
  }, []);

  return (
    <>
      {assetWorkspace.hasLoaded && (
        <>
          <Center>
            <Title order={1}> Taproot Assets Exchange</Title>
          </Center>
          <Paper withBorder p="md">
            <Title order={3}>
              Taproot Assets: a responsible assets framework for Lightning
            </Title>

            <List size="sm">
              <List.Item>
                Taproot Assets is a data standard and a set of cryptography
                tools for assets and stablecoins, backed by the security of the
                Bitcoin network, and designed to be transacted over Lightning
                channels.
              </List.Item>
              <List.Item>
                Unlike Ordinals or Runes, asset data is NOT stored on the
                Bitcoin blockchain.
              </List.Item>
              <List.Item>
                No new consensus or standardness rule changes are required to
                support Taproot Assets.
              </List.Item>
              <List.Item>
                Taproot Asset creation transactions look like any other taproot
                UTXOs on the blockchain. They take up very little block space.
              </List.Item>
              {/* <List.Item>
                Taproot Assets can currently be traded on the main Bitcoin
                network, and trading only requires a very space-efficient
                transaction.
              </List.Item> */}

              <List.Item>
                Taproot Assets which advertise the "type: normal" flag can be
                transferred on the Lightning Network. Megalith is building
                <Anchor
                  href="https://docs.megalithic.me/taproot-assets/megalith-assets"
                  target="_blank"
                >
                  {" "}
                  an automated exchange service{" "}
                </Anchor>
                for these types of assets.
              </List.Item>
              <List.Item>
                Existing Lightning nodes are able to forward Taproot Asset
                transfers without upgrading their software.
              </List.Item>

              <List.Item>
                Trading these assets over the Lightning Network requires the
                transmission of BTC. This increase the overall utility and
                transaction volume of the Network.
              </List.Item>
            </List>
          </Paper>
          <Center>
            <Title pt="md" order={2}>
              {" "}
              Assets That We Are Buying Or Selling
            </Title>
          </Center>
          <AssetTable assetWorkspace={assetWorkspace} />

          <Paper withBorder m="md" p="md">
            <Title p="md" order={3}>
              Ethical guidelines for dealers in Taproot Assets
            </Title>
            <List size="sm">
              <List.Item>
                Only accept payment, and redeem payment, in Bitcoin.
              </List.Item>
              <List.Item>
                When Lightning Network support is available, only make trades on
                the Lightning Network, not on the mainchain.
              </List.Item>
              <List.Item>
                Never engage in misleading marketing practices
              </List.Item>
            </List>
          </Paper>
          <Title p="md" order={3}>
            Some statistics about the known assets
          </Title>
          <AssetStats
            assetData={
              assetWorkspace.assetsWorkspaceDataFrozen.data
                .single_setting_assets_data
            }
          />

          <Title p="md" order={3}>
            Frequently Asked Questions
          </Title>
          <Paper withBorder m="md" p="md">
            <Stack>
              <Title order={4}>
                The image, text, or JSON data in these assets: Where is it
                stored?
              </Title>
              <Text>
                This metadata is stored on the creator's computers, and
                redundantly in the Universes. Note that some asset creators are
                attempting to link to external data sources, like images and
                websites, by including URLs in their asset data, but we are
                ignoring these links and only considering data written
                permanently to the Universe stores.
              </Text>
              <Title mt="md" order={4}>
                What are the Universes?
              </Title>
              <Text>
                After you create a Taproot Asset, you can sync your asset data
                with a public universe. We currently know about these public
                universes:
              </Text>

              <KnownUniverses />
              {/* <Title mt="md" order={4}>
                Do you plan to offer a trading platform?
              </Title>
              <Text>
                Yes. After Taproot Assets can be reliably exchanged over the
                Lightning Network, this page will transition into an open,
                permissionless exchange.
              </Text> */}

              <Title mt="md" order={4}>
                How are you deciding which assets you want to buy?
              </Title>
              <Text>
                We're currently buying early assets that were created by Bitcoin
                believers.
              </Text>

              <Title mt="md" order={4}>
                Is this for Degens?
              </Title>
              <Text>
                No. If you believe in Bitcoin, you should support increased
                activity on the Lightning Network. Assets and stablecoins are
                two use-cases that won't be going away anytime soon, in fact,
                their adoption is growing, and fast. It's better that these
                needs are fulfilled by Bitcoin, instead of dubious altcoins.
                Taproot Assets allows us to service these needs while
                strengthening Bitcoin's network effects.
              </Text>
            </Stack>
          </Paper>
        </>
      )}
    </>
  );
});

export default AssetWorkspaceContainer;
