import { Code } from "@mantine/core";
import React from "react";

const KnownUniverses = ({}) => {
  const knownUniverses = `assets.megalith-node.com
universe.lightning.finance
universe.tiramisuwallet.com
universe.nostrassets.com`;

  return <Code block>{knownUniverses}</Code>;
};

export default KnownUniverses;
