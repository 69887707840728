import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
  Image,
  Tooltip,
  Paper,
  Textarea,
  Title,
  SimpleGrid,
  Anchor,
  Blockquote,
} from "@mantine/core";
import _ from "lodash";

import { DataTable } from "mantine-datatable";
import { isMobileOnly } from "react-device-detect";
import { ExplorerWorkspace } from "../../stores/ExplorerWorkspace";

const LnFi = observer(
  ({ explorerWorkspace }: { explorerWorkspace: ExplorerWorkspace }) => {
    const searchResults = explorerWorkspace.enhancedAssets;
    useEffect(() => {}, []);
    const asset = searchResults[0];
    console.log("this asset uses nostr");

    const computeBalanceToShow = () => {
      if (!explorerWorkspace.nostrRelatedDataHasPopulated) {
        return null;
      }
      if (
        !explorerWorkspace.nostrRelatedData.data.hasOwnProperty(
          asset.asset_name
        )
      ) {
        return null;
      }
      if (
        !explorerWorkspace.nostrRelatedData.data[asset.asset_name].balanceShow
      ) {
        return null;
      }

      return explorerWorkspace.nostrRelatedData.data[asset.asset_name]
        .balanceShow;
    };
    return (
      <>
        {computeBalanceToShow() && (
          <>
            <Blockquote
              color="blue"
              cite={
                "public key: " +
                _.truncate(explorerWorkspace.nostrPublicKey, { length: 16 })
              }
              mt="md"
            >
              You hold {computeBalanceToShow()} {asset.asset_name}
            </Blockquote>
          </>
        )}
      </>
    );
  }
);

export default LnFi;
