import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  ExplorerWorkspace,
  ExplorerWorkspaceContext,
} from "./stores/ExplorerWorkspace";

import {
  Center,
  Code,
  Stack,
  Title,
  Text,
  List,
  Anchor,
  Paper,
  TextInput,
  Loader,
  CloseButton,
} from "@mantine/core";

const SampleSearches = observer(
  ({ explorerWorkspace }: { explorerWorkspace: ExplorerWorkspace }) => {
    useEffect(() => {}, []);
    return (
      <>
        <Paper withBorder shadow="xs" p="xl">
          {/* <Title order={3}> searches..</Title> */}
          <Text>demo searches...</Text>
          <Text>
            asset id:{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "f22e93e868a4e24a41935ed52a12301c5bc314d54294d3e618b73b566b55ec93"
                )
              }
            >
              f22e93
            </Anchor>
            ,{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "af7b850be041d02eba1131ce752ab458e97b15774abd2260170ed055fd3bcf8a"
                )
              }
            >
              af7b850
            </Anchor>
            ,{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "c5a2ce06b0c8a72142de4df3879825d608491095894663a51e886a01d08921b7"
                )
              }
            >
              c5a2ce
            </Anchor>
          </Text>
          <Text>
            text:{" "}
            <Anchor onClick={() => explorerWorkspace.updateSearch("btc")}>
              btc
            </Anchor>
            ,{" "}
            <Anchor onClick={() => explorerWorkspace.updateSearch("goose")}>
              goose
            </Anchor>
            ,{" "}
            <Anchor onClick={() => explorerWorkspace.updateSearch("japanese")}>
              japanese
            </Anchor>
            ,{" "}
            <Anchor onClick={() => explorerWorkspace.updateSearch("funny")}>
              funny
            </Anchor>
          </Text>
          <Text>
            genesis point:{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "877aeb21bc368b2f4dd64c32847924c5da618920e36b0b6fb9ad8af737beb98b:0"
                )
              }
            >
              877aeb
            </Anchor>
            ,{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "40e549bbab1072ea0a262ce002763383846e8797994b707f5690f83106e70e36:1"
                )
              }
            >
              40e549
            </Anchor>
            ,{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "77f867378b1879b7cb4d0b272ab95a66c9b0f76e03fcf31e5269f9beba0344b6:1"
                )
              }
            >
              77f867
            </Anchor>
          </Text>
          <Text>
            anchor point:{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "b8ef8e9030445fac119944190bf2f7a3026c9a12090fe8e422227577707d9ae1:0"
                )
              }
            >
              b8ef8e90
            </Anchor>
            ,{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "c0d955f96ca9936de1c81cad8ac1ac260d799bbd96c916a6648be5768a53a34a:0"
                )
              }
            >
              c0d955f
            </Anchor>
            ,{" "}
            <Anchor
              onClick={() =>
                explorerWorkspace.updateSearch(
                  "41b128c7c1c1afc8781a9dab1e7120c2f01c6c0a88e391f6e1d2ad0d9b50db23:0"
                )
              }
            >
              41b128
            </Anchor>
          </Text>
        </Paper>
      </>
    );
  }
);

export default SampleSearches;
