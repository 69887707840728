declare global {
  interface Window {
    nostr?: {
      getPublicKey: () => Promise<string>;
    };
    alby?: {
      nostr: any;
    };
    okxwallet?: {
      nostr: any;
    };
    $onekey?: {
      nostr: any;
    };
    tokenpocket?: {
      nostr: any;
    };
    nostrasset?: {
      nostr?: any;
    };
    _notification?: {
      warning: (options: {
        key: string;
        icon: any;
        message: string;
        description: string;
      }) => void;
    };
  }
}

import { nip19 } from "nostr-tools";
import { notifications } from "@mantine/notifications";

// export const tryToGetNostrPublicKey = async () => {
//   if (!window.nostr) {
//     return null;
//   }

//   try {
//     const publicKey = await window.nostr.getPublicKey();
//     const npubKey = nip19.npubEncode(publicKey);
//     return npubKey;
//   } catch (error) {
//     console.error("Error getting Nostr public key:", error);
//     return null;
//   }
// };

export const NOSTR_PLATFORM_NAMES = {
  GENERIC: "generic",
  OKX: "okx",
  ONEKEY: "onekey",
  TOKENPOCKET: "tokenpocket",
  ALBY: "alby",
};

export const handleNostrButtonAttempt = async (nostrPlatform) => {
  let instanceOfNostrFunction = null;

  if (nostrPlatform === NOSTR_PLATFORM_NAMES.ALBY) {
    // if (window.alby) {
    // if (
    //   window?.okxwallet?.nostr ||
    //   window.$onekey?.nostr ||
    //   window?.tokenpocket?.nostr
    // ) {
    //   console.log("hey, disable the other nostr plugins here cuz u on alby");
    // }

    // }
    instanceOfNostrFunction = window?.alby?.nostr;
    const nPubKey = await handleAlbyWallet(instanceOfNostrFunction);
    return nPubKey;
  } else if (nostrPlatform === NOSTR_PLATFORM_NAMES.OKX) {
    instanceOfNostrFunction = window?.okxwallet?.nostr;
    const nPubKey = await handleOkXWallet(instanceOfNostrFunction);
    return nPubKey;
  } else if (nostrPlatform === NOSTR_PLATFORM_NAMES.ONEKEY) {
    instanceOfNostrFunction = window?.$onekey?.nostr;
    const nPubKey = await handleOneKeyWallet(instanceOfNostrFunction);
    return nPubKey;
  } else if (nostrPlatform === NOSTR_PLATFORM_NAMES.TOKENPOCKET) {
    instanceOfNostrFunction = window?.tokenpocket?.nostr;
    const nPubKey = await handleTokenPocketWallet(instanceOfNostrFunction);
    return nPubKey;
  } else {
    instanceOfNostrFunction = window.nostr;
    const nPubKey = await handleGenericWallet(instanceOfNostrFunction);
    return nPubKey;
  }
  // console.log("this is where i would set this nostr platform", nostrPlatform);

  // const publicKey = await instanceOfNostrFunction.getPublicKey();
  // console.log("public key after await", publicKey);
  // return publicKey;
};

const handleOkXWallet = async (instanceOfNostrFunction) => {
  try {
    const publicKey = await instanceOfNostrFunction.getPublicKey();
    console.log("here is the oks public key need to convert", publicKey);
    const npubKey = nip19.npubEncode(publicKey);
    console.log("here is the npubkey", npubKey);
    return npubKey;
  } catch (error) {
    console.error("Error getting Nostr public key:", error);
    notifications.show({
      color: "red",
      title: "Error with OKX Wallet",
      message: "Please be sure the OKX browser extension is installed.",
    });
    return null;
  }
};

const handleOneKeyWallet = async (instanceOfNostrFunction) => {
  try {
    console.log(
      "here is the instance where i could await the publickey ",
      instanceOfNostrFunction
    );
    const publicKey = await instanceOfNostrFunction.getPublicKey();
    console.log("here is the onekey public key need to convert", publicKey);
    const npubKey = nip19.npubEncode(publicKey);
    console.log("here is the npubkey", npubKey);
    return npubKey;
  } catch (error) {
    console.error("Error getting Nostr public key:", error);
    notifications.show({
      color: "red",
      title: "Error with OneKey Wallet",
      message: "Please be sure the OneKey browser extension is installed.",
    });
    return null;
  }
};

const handleTokenPocketWallet = async (instanceOfNostrFunction) => {
  try {
    console.log(
      "here is the instance where i could await the publickey ",
      instanceOfNostrFunction
    );
    const publicKey = await instanceOfNostrFunction.getPublicKey();
    console.log("here is the onekey public key need to convert", publicKey);
    const npubKey = nip19.npubEncode(publicKey);
    console.log("here is the npubkey", npubKey);
    return npubKey;
  } catch (error) {
    console.error("Error getting Nostr public key:", error);
    notifications.show({
      color: "red",
      title: "Error with TokenPocket",
      message: "Please be sure the TokenPocket browser extension is installed.",
    });
    return null;
  }
};

const handleAlbyWallet = async (instanceOfNostrFunction) => {
  try {
    console.log(
      "here is the instance where i could await the publickey ",
      instanceOfNostrFunction
    );
    const publicKey = await instanceOfNostrFunction.getPublicKey();
    console.log("here is the onekey public key need to convert", publicKey);
    const npubKey = nip19.npubEncode(publicKey);
    console.log("here is the npubkey", npubKey);
    return npubKey;
  } catch (error) {
    console.error("Error getting Nostr public key:", error);
    notifications.show({
      color: "red",
      title: "Error with Alby",
      message: "Please be sure the Alby browser extension is installed.",
    });
    return null;
  }
};

const handleGenericWallet = async (instanceOfNostrFunction) => {
  try {
    console.log(
      "here is the instance where i could await the publickey ",
      instanceOfNostrFunction
    );
    const publicKey = await instanceOfNostrFunction.getPublicKey();
    console.log("here is the onekey public key need to convert", publicKey);
    const npubKey = nip19.npubEncode(publicKey);
    console.log("here is the npubkey", npubKey);
    return npubKey;
  } catch (error) {
    console.error("Error getting Nostr public key:", error);
    notifications.show({
      color: "red",
      title: "Error with Nostr extension",
      message: "Please be sure a Nostr browser extension is installed",
    });
    return null;
  }
};
