// LogoAnchor.tsx

import React from "react";
import { Anchor, Text } from "@mantine/core";

interface LogoAnchorProps {
  showImage: boolean;
}

const LogoAnchor: React.FC<LogoAnchorProps> = ({ showImage }) => (
  <Anchor href="/" style={{ textDecoration: "none", color: "inherit" }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      {showImage && (
        <img
          height={35}
          width="auto"
          src="https://d3u5cu1kr5hxm.cloudfront.net/images/megalith-logo-400.webp"
          alt="Megalithic Logo"
          style={{ verticalAlign: "middle", marginRight: "10px" }}
        />
      )}
      <Text size="lg">megalithic.me</Text>
    </div>
  </Anchor>
);

export default LogoAnchor;
