import React from "react";
import { computed, observable } from "mobx";
import {
  registerRootStore,
  fromSnapshot,
  frozen,
  Frozen,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  onSnapshot,
  prop,
  Ref,
  undoMiddleware,
  _async,
  _await,
  idProp,
  getNodeSandboxManager,
} from "mobx-keystone";
import { format } from "d3-format";
import { createContext } from "react";
import { convertSatoshisToUsd, formatSupply } from "../helpers/numbers";
import { NO_IMAGE_DATA_URL } from "../helpers/constants";
import _ from "lodash";
import { sendToRailsController } from "../helpers/requests";
import { updateSearchQueryParam } from "../helpers/browser";
import { sortOriginalsFirst } from "../helpers/sort";
import { v4 as uuidv4 } from "uuid";
import { isMobileOnly } from "react-device-detect";
import {
  handleNostrButtonAttempt,
  NOSTR_PLATFORM_NAMES,
} from "../helpers/nostr";
import {
  setNostrDataToLocalStorage,
  getNostrDataFromLocalStorage,
} from "../helpers/localStorage";
// import { tryToGetNostrPublicKey } from "../helpers/nostr";

@model("megalithic/ExplorerWorkspace")
export class ExplorerWorkspace extends Model({
  id: idProp,
  hasLoaded: prop<boolean>(false),
  isMobile: prop<boolean>(false),
  explorerWorkspaceDataFrozen: prop<Frozen<any> | undefined>(undefined),
  searchResultsFrozen: prop<Frozen<any>>(() => frozen([])),
  searchBoxValue: prop<string>(""),
  searchIsLoading: prop<boolean>(false),
  previousSearch: prop<string | undefined>(undefined),
  workspaceUuid: prop<string>(() => uuidv4()),
  assetProofFrozen: prop<Frozen<any>>(() => frozen({})),
  assetProofStatus: prop<string>(""),
  assetProofInProgress: prop<boolean>(false),
  nostrPublicKey: prop<string | null>(null),
  nostrPlatform: prop<string | null>(null),
  nostrRelatedData: prop<Frozen<any>>(() => frozen({})),
  nostrRelatedDataHasPopulated: prop<boolean>(false),
}) {
  @computed
  get linkToCurrentSearch() {
    const domain =
      this.explorerWorkspaceDataFrozen.data.environment_details.domain;
    const withIntro =
      "https://" +
      domain +
      "/" +
      "taproot_assets_explorer?search=" +
      this.searchBoxValue;
    return withIntro;
  }
  @computed
  get shouldShowXButton() {
    return this.searchIsLoading == false && this.searchBoxValue.length > 0;
  }

  @modelAction
  setHasLoaded(hasLoaded: boolean) {
    this.hasLoaded = hasLoaded;
  }
  @modelAction
  clearSearch() {
    this.searchBoxValue = "";
    this.clearSearchResults();
  }

  @modelAction
  clearSearchResults() {
    this.searchResultsFrozen = frozen([]);
  }
  @modelAction
  restorePreviousSearch() {
    this.clearSearchResults();
    this.updateSearch(this.previousSearch);
    this.previousSearch = undefined;
  }

  @modelAction
  makeANewSearch(asset_universe_id) {
    this.previousSearch = this.searchBoxValue;
    this.updateSearch(asset_universe_id);
  }
  @modelAction
  updateSearch(newValue: string) {
    this.clearSearchResults();
    this.searchBoxValue = newValue;
    if (this.searchBoxValue.length > 0) {
      this.searchIsLoading = true;
      this.submitSearch();
    } else {
      this.searchIsLoading = false;
    }
    updateSearchQueryParam(newValue);
  }

  @computed
  get enhancedAssets() {
    const baseAssets = sortOriginalsFirst(this.searchResultsFrozen.data);
    const enhancedAssets = baseAssets.map((asset) => {
      const dateObject = new Date(asset.asset_genesis_date);
      const formattedDate = dateObject.toLocaleDateString(undefined, {
        timeZone: "UTC",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const formattedTime = dateObject.toLocaleTimeString(undefined, {
        timeZone: "UTC",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      const enhancedAsset = {
        ...asset,
        modifiedImageUrlData: asset.image_uri
          ? asset.image_uri
          : NO_IMAGE_DATA_URL,
        formattedSupply: formatSupply(asset.asset_total_supply),
        assetGenesisDate: formattedDate,
        assetGenesisTime: formattedTime,
        truncatedAssetName: _.truncate(asset.asset_name, {
          length: 26,
        }),
        isOriginal: asset.duplicate_of_asset_universe_id ? "false" : "true",
      };
      return enhancedAsset;
    });
    return enhancedAssets;
  }
  @modelFlow
  submitSearch = _async(function* (this: ExplorerWorkspace) {
    const message = {
      query: this.searchBoxValue,
    };
    sendToRailsController(message, "/search")
      .then((response) => {
        // console.log("response");
        // console.log(response);
        this.handleSearchResult(response);
        // if (response.original_query == this.searchBoxValue) {
        //   this.searchIsLoading = false;
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  @modelFlow
  requestAssetValidation = _async(function* (
    this: ExplorerWorkspace,
    proof_as_text: string
  ) {
    this.assetProofStatus = "";
    this.assetProofFrozen = frozen({});
    this.assetProofInProgress = true;
    const message = {
      proof_as_text: proof_as_text,
      explorer_workspace_uuid: this.workspaceUuid,
    };
    sendToRailsController(message, "/validate_asset_ownership")
      .then((response) => {
        console.log("response");
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  // @modelAction
  // maybeCheckForNostr() {
  //   const searchResults = this.enhancedAssets;
  //   if (searchResults.length > 1) {
  //     //console.log("more than one asset");
  //     return;
  //   }
  //   if (searchResults.length == 0) {
  //     //console.log("no assets");
  //     return;
  //   }
  //   const asset = searchResults[0];
  //   if (!asset.nostr_directive) {
  //     //console.log("no nostr directive");
  //     return;
  //   }
  //   console.log("checking for nostr");
  //   this.checkforNostr();
  // }

  // @modelFlow
  // checkforNostr = _async(function* (this: ExplorerWorkspace) {
  //   const publicKey = yield* _await(tryToGetNostrPublicKey());
  //   //console.log("here is the public key", publicKey);
  //   if (publicKey) {
  //     this.nostrPublicKey = publicKey;
  //     this.requestLnfiData(publicKey);
  //   }
  // });

  @modelAction
  setNostrAlsoToStorage(
    publicKey: string | null,
    nostrPlatform: string | null
  ) {
    this.nostrPublicKey = publicKey;
    this.nostrPlatform = nostrPlatform;
    this.nostrRelatedData = frozen({});
    this.nostrRelatedDataHasPopulated = false;
    setNostrDataToLocalStorage(publicKey, nostrPlatform);
  }

  @modelFlow
  handleLoginToNostrButton = _async(function* (
    this: ExplorerWorkspace,
    nostrPlatform: string
  ) {
    const publicKey = yield* _await(handleNostrButtonAttempt(nostrPlatform));
    console.log("here is the public key", publicKey);
    if (publicKey) {
      this.setNostrAlsoToStorage(publicKey, nostrPlatform);
      this.requestLnfiData(publicKey);
    }
  });

  @computed
  get thisPageRequiresNostrLogic() {
    if (this.isMobile) {
      return false;
    }
    const searchResults = this.enhancedAssets;
    if (searchResults.length > 1) {
      console.log("more than one asset");
      return false;
    }
    if (searchResults.length == 0) {
      console.log("no assets");
      return false;
    }
    const asset = searchResults[0];
    if (!asset.nostr_directive) {
      console.log("no nostr directive");
      return false;
    }
    return true;
  }
  @modelFlow
  requestLnfiData = _async(function* (
    this: ExplorerWorkspace,
    nostr_public_key: string
  ) {
    const message = {
      nostr_public_key: nostr_public_key,
    };
    sendToRailsController(message, "/nostr/get_lnfi_data")
      .then((response) => {
        console.log("response");
        if (response.lnfi_data) {
          this.handleNostrRelatedData(response.lnfi_data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
  @modelAction
  handleNostrRelatedData(result: any) {
    this.nostrRelatedData = frozen(result);
    this.nostrRelatedDataHasPopulated = true;
    console.log(this.nostrRelatedData.data);
  }

  @modelAction
  afterSearchHasPopulated() {
    if (this.nostrPublicKey) {
      console.log('public key is  set"');
      console.log(
        "value of this page requires nostr logic",
        this.thisPageRequiresNostrLogic
      );
      if (this.thisPageRequiresNostrLogic) {
        console.log("this page requires nostr logic");
        this.requestLnfiData(this.nostrPublicKey);
      }
    }

    //this.maybeCheckForNostr();
  }
  @modelAction
  handleSearchResult(result: any) {
    console.log("result");
    console.log(result);
    if (result.original_query == this.searchBoxValue) {
      this.searchIsLoading = false;
    }
    this.searchResultsFrozen = frozen(result.results);
    this.afterSearchHasPopulated();
    console.log("at end of handle search result");
  }

  @modelAction
  searchBasedOnUrl() {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchQuery = urlObj.searchParams.get("search") || null;
    if (searchQuery) {
      this.updateSearch(searchQuery);
    }
  }

  @modelAction
  hydrateDataFromPage() {
    const rootDiv = document.getElementById("root");
    const json = JSON.parse(rootDiv.dataset.hydrate);
    // console.log("here is the data from the page", json);
    this.explorerWorkspaceDataFrozen = frozen(json);
    // console.log(
    //   "here is the data from the page",
    //   this.explorerWorkspaceDataFrozen.data
    // );

    this.setHasLoaded(true);
    this.searchBasedOnUrl();
    if (isMobileOnly) {
      this.isMobile = true;
    }
    const nostrData = getNostrDataFromLocalStorage();
    console.log("nostr from local storage", nostrData);
    this.nostrPublicKey = nostrData.nostrPublicKey;
    this.nostrPlatform = nostrData.nostrPlatform;
  }

  @modelAction
  handleVerifyAndDecodeProof(data: any) {
    this.assetProofStatus = "";
    this.assetProofInProgress = false;
    if (data.outcome == "success") {
      this.assetProofFrozen = frozen(data.decoded_proof);
      this.assetProofStatus = "success";
    }
    if (data.outcome == "failure") {
      this.assetProofStatus = "failure";
      this.assetProofFrozen = frozen({});
    }
  }

  @computed
  get foo() {
    return "bar";
  }
}

export const runCreateExplorerWorkspace = () => {
  const explorerWorkspace = new ExplorerWorkspace({});
  registerRootStore(explorerWorkspace);
  return explorerWorkspace;
};

const createdExplorerWorkspace = runCreateExplorerWorkspace();
//console.log("created workspace", getSnapshot(createdWorkspace));
export const ExplorerWorkspaceContext = createContext<ExplorerWorkspace>(
  createdExplorerWorkspace
);

export interface ExplorerWorkspaceInterface {
  ExplorerWorkspace: ExplorerWorkspace;
}
