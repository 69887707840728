export const sendToRailsController = async (data: object, url: string) => {
  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken, // Include the CSRF token in the headers
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
