export const setNostrDataToLocalStorage = (
  nostrPublicKey: string,
  nostrPlatform: string
) => {
  localStorage.setItem("nostrPublicKey", nostrPublicKey);
  localStorage.setItem("nostrPlatform", nostrPlatform);
};

export const getNostrDataFromLocalStorage = () => {
  const nostrPublicKey = localStorage.getItem("nostrPublicKey");
  const nostrPlatform = localStorage.getItem("nostrPlatform");

  return {
    nostrPublicKey: nostrPublicKey === "null" ? null : nostrPublicKey,
    nostrPlatform: nostrPlatform === "null" ? null : nostrPlatform,
  };
};
