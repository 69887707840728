import { timeFormat } from "d3-time-format";

// Arrow function to convert Unix timestamp to a nicely formatted local time string
export const formatUnixTimestamp = (unixTimestamp) => {
  // Convert Unix timestamp to milliseconds, create a Date object, and format it
  /// %I:%M:%S
  return timeFormat("%B %d, %Y")(new Date(unixTimestamp * 1000));
};

export const formatUnixTimeStampWithTime = (unixTimestamp) => {
  const utcDate = new Date(unixTimestamp * 1000).toISOString();
  return timeFormat("%B %d, %Y %I:%M:%S %p")(new Date(utcDate));
};
