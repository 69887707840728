export const sortOriginalsFirst = (data) => {
  // Create a copy of the data array
  const dataCopy = [...data];

  // Sort the copy of the array
  return dataCopy.sort((a, b) => {
    // Your sorting logic remains the same
    if (
      !a.duplicate_of_asset_universe_id &&
      !b.duplicate_of_asset_universe_id
    ) {
      return 0;
    } else if (!a.duplicate_of_asset_universe_id) {
      return -1;
    } else if (!b.duplicate_of_asset_universe_id) {
      return 1;
    } else {
      return 0;
    }
  });
};
