import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
  Paper,
  SimpleGrid,
  Title,
} from "@mantine/core";

import {
  IconCoinBitcoin,
  IconCoin,
  IconDatabase,
  IconClockHour10,
  IconGift,
  IconCoins,
  IconGif,
  IconPng,
  IconSvg,
  IconJpg,
  IconJson,
  IconAbc,
  IconPhoto,
  IconFileUnknown,
} from "@tabler/icons-react";
import classes from "./PowerListStats.module.css";

import { PowerList } from "../stores/PowerList";
import {
  dateTimeToTimeAgo,
  formatUSDAmount,
  formatBitcoinPrice,
  formatNumberWithCommas,
  convertKBtoGB,
} from "../helpers/numbers";

import { AssetWorkspace } from "../stores/AssetWorkspace";

const AssetStats = observer(({ assetData }: { assetData: any }) => {
  return (
    <>
      <SimpleGrid cols={{ base: 2, xs: 3, md: 5 }}>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Total Known Assets
            </Text>
            {/* <IconCoin className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.total_assets.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            in known universes
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Universe Data
            </Text>
            <IconDatabase className={classes.icon} size="1.4rem" stroke={1.5} />
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {convertKBtoGB(assetData.universe_data_file_size)}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            Gigabytes
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Last Asset
            </Text>
            <IconClockHour10
              className={classes.icon}
              size="1.4rem"
              stroke={1.5}
            />
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {dateTimeToTimeAgo(assetData.most_recently_discovered_asset)}
            </Text>
            <Text fz="xs" c="dimmed" mt={7}>
              in known universes
            </Text>
          </Group>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Collectibles
            </Text>
            <IconGift className={classes.icon} size="1.4rem" stroke={1.5} />
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_collectible_assets.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            one-of-a-kind assets
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Normal Assets
            </Text>
            <IconCoins className={classes.icon} size="1.4rem" stroke={1.5} />
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_normal_assets.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets created in multiples
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              GIF
            </Text>
            {/* <IconGif className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_gif.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with GIF image data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              PNG
            </Text>
            {/* <IconPng className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_png.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with PNG image data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              SVG
            </Text>
            {/* <IconSvg className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_svg.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with SVG image data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              HEIC
            </Text>
            {/* <IconCoin className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_heic.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with HEIC image data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              WEBP
            </Text>
            {/* <IconCoin className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_webp.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with WEBP image data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              JPG
            </Text>
            {/* <IconJpg className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_jpeg.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with JPG image data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              JSON
            </Text>
            {/* <IconJson className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_in_json.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with JSON data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Text
            </Text>
            {/* <IconAbc className={classes.icon} size="1.4rem" stroke={1.5} /> */}
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_with_valid_text_data.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with text data
          </Text>
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className={classes.title}>
              Unknown
            </Text>
            <IconFileUnknown
              className={classes.icon}
              size="1.4rem"
              stroke={1.5}
            />
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text className={classes.value}>
              {assetData.num_assets_with_unknown_data_format.toLocaleString()}
            </Text>
          </Group>
          <Text fz="xs" c="dimmed" mt={7}>
            assets with unknown data format
          </Text>
        </Paper>
      </SimpleGrid>
    </>
  );
});

export default AssetStats;
