import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useRef } from "react";

import { createTheme, MantineProvider, Container } from "@mantine/core";

import PowerListContainer from "./PowerListContainer";
import MegalithContainer from "./MegalithContainer";

import { HeaderSimple } from "./components/Header";
import { FooterSimple } from "./components/Footer";

import ContactContainer from "./components/ContactContainer";
import PrivacyAndTermsContainer from "./components/PrivacyAndTermsContainer";
import AssetsWorkspaceContainer from "./AssetsWorkspaceContainer";
import ExplorerWorkspaceContainer from "./ExplorerWorkspaceContainer";
import { Notifications } from "@mantine/notifications";
const theme = createTheme({
  /** Put your mantine theme override here */
});

const AppContainer: React.FC = observer(() => {
  // const descriptionRef = useRef(null);
  // const scrollToBottom = () => {
  //   if (descriptionRef.current) {
  //     const scrollableElement = descriptionRef.current;
  //     scrollableElement.scrollTop = scrollableElement.scrollHeight;
  //   }
  // };

  useEffect(() => {}, []);
  console.log(window.location.pathname);
  return (
    <MantineProvider
      defaultColorScheme="dark"
      theme={{
        fontFamily: "courier-prime, monospace",
        fontFamilyMonospace: "courier-prime, monospace",
        //headings: { fontFamily: "Greycliff CF, sans-serif" },
      }}
    >
      <Container>
        <Notifications position="top-center" />
        <HeaderSimple />

        {window.location.pathname === "/power_list" && <PowerListContainer />}
        {window.location.pathname === "/" && <MegalithContainer />}
        {window.location.pathname === "/contact" && <ContactContainer />}
        {window.location.pathname === "/privacy_and_terms" && (
          <PrivacyAndTermsContainer />
        )}
        {window.location.pathname === "/taproot_assets" && (
          <AssetsWorkspaceContainer />
        )}
        {window.location.pathname === "/taproot_assets_explorer" && (
          <ExplorerWorkspaceContainer />
        )}

        <FooterSimple />
      </Container>
    </MantineProvider>
  );
});

export default AppContainer;
