import React from "react";
import { Card, Text, Title, Divider } from "@mantine/core";
import CustomTextarea from "./CustomTextarea";

function ShowProof({ data }) {
  return (
    <div style={{ margin: "1rem", maxWidth: 900 }}>
      <Card shadow="sm" padding="lg">
        <Title order={2}>Ownership Validated</Title>
        <Text>Amount owned: {data.asset.amount}</Text>
        {/* <Text>Number of Proofs: {data.number_of_proofs}</Text> */}

        <Text>Is Burn: {data.is_burn ? "Yes" : "No"}</Text>

        <Divider my="sm" />

        <div>
          <CustomTextarea
            label="Name"
            value={data.asset.asset_genesis.name}
            maw="98%"
          />
          <CustomTextarea
            label="Asset ID"
            value={data.asset.asset_genesis.asset_id}
            maw="98%"
          />
          <CustomTextarea
            label="Type"
            value={data.asset.asset_genesis.asset_type}
            maw="98%"
          />
          <CustomTextarea
            label="Genesis Point"
            value={data.asset.asset_genesis.genesis_point}
            maw="98%"
          />
          <CustomTextarea
            label="Meta Hash"
            value={data.asset.asset_genesis.meta_hash}
            maw="98%"
          />
          <CustomTextarea
            label="Output Index"
            value={data.asset.asset_genesis.output_index}
            maw="98%"
          />

          <CustomTextarea
            label="Version"
            value={data.asset.version}
            maw="98%"
          />
          <CustomTextarea
            label="Lock Time"
            value={data.asset.lock_time}
            maw="98%"
          />
          <CustomTextarea
            label="Relative Lock Time"
            value={data.asset.relative_lock_time}
            maw="98%"
          />
          <CustomTextarea
            label="Script Version"
            value={data.asset.script_version}
            maw="98%"
          />
          <CustomTextarea
            label="Script Key"
            value={data.asset.script_key}
            maw="98%"
          />
          <CustomTextarea
            label="Script Key is Local"
            value={data.asset.script_key_is_local ? "Yes" : "No"}
            maw="98%"
          />

          <CustomTextarea
            label="Tweaked Group Key"
            value={data.asset.asset_group.tweaked_group_key}
            maw="98%"
          />
          <CustomTextarea
            label="Asset Witness"
            value={data.asset.asset_group.asset_witness}
            maw="98%"
          />

          <CustomTextarea
            label="Anchor Transaction"
            value={data.asset.chain_anchor.anchor_tx}
            maw="98%"
          />
          <CustomTextarea
            label="Anchor Block Hash"
            value={data.asset.chain_anchor.anchor_block_hash}
            maw="98%"
          />
          <CustomTextarea
            label="Anchor Outpoint"
            value={data.asset.chain_anchor.anchor_outpoint}
            maw="98%"
          />
          <CustomTextarea
            label="Internal Key"
            value={data.asset.chain_anchor.internal_key}
            maw="98%"
          />
          <CustomTextarea
            label="Merkle Root"
            value={data.asset.chain_anchor.merkle_root}
            maw="98%"
          />

          <CustomTextarea
            label="Merkle Proof"
            value={data.tx_merkle_proof}
            maw="98%"
          />
          <CustomTextarea
            label="Inclusion Proof"
            value={data.inclusion_proof}
            maw="98%"
          />
          {data.exclusion_proofs.map((proof, index) => (
            <CustomTextarea
              key={index}
              label={`Exclusion Proof ${index + 1}`}
              value={proof}
              maw="98%"
            />
          ))}
          <CustomTextarea
            label="Split Root Proof"
            value={data.split_root_proof}
            maw="98%"
          />
        </div>
      </Card>
    </div>
  );
}

export default ShowProof;
