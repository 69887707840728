import _, { get } from "lodash";
import React from "react";
import { computed, observable } from "mobx";
import {
  registerRootStore,
  fromSnapshot,
  frozen,
  Frozen,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  onSnapshot,
  prop,
  Ref,
  undoMiddleware,
  _async,
  _await,
  idProp,
} from "mobx-keystone";

import { createContext } from "react";

@model("megalithic/Megalith")
export class Megalith extends Model({
  id: idProp,
  hasLoaded: prop<boolean>(false),
  megalithDataFrozen: prop<Frozen<any> | undefined>(),
}) {
  @modelAction
  setHasLoaded(hasLoaded: boolean) {
    this.hasLoaded = hasLoaded;
  }
  @modelAction
  hydrateDataFromPage() {
    const rootDiv = document.getElementById("root");
    const json = JSON.parse(rootDiv.dataset.hydrate);
    // console.log("here is the data from the page", json);
    this.megalithDataFrozen = frozen(json);
    this.setHasLoaded(true);
  }
}

export const runCreateMegalith = () => {
  const megalith = new Megalith({});
  registerRootStore(megalith);
  return megalith;
};

const createdMegalith = runCreateMegalith();
//console.log("created workspace", getSnapshot(createdWorkspace));
export const MegalithContext = createContext<Megalith>(createdMegalith);

export interface MegalithInterface {
  Megalith: Megalith;
}
