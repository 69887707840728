import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { PowerList, PowerListContext } from "./stores/PowerList";

import {
  createTheme,
  MantineProvider,
  Container,
  Stack,
  Center,
  Box,
  Anchor,
  Text,
  Divider,
  SimpleGrid,
} from "@mantine/core";
import PowerListTable from "./components/PowerListTable";
import { Notifications } from "@mantine/notifications";
import { Title } from "@mantine/core";
import { makeLinkFromSquireName } from "./helpers/routes";
import PowerListStats from "./components/PowerListStats";
import { isMobileOnly } from "react-device-detect";
import { IconBolt } from "@tabler/icons-react";
// const theme = createTheme({
//   /** Put your mantine theme override here */
// });

const PowerListContainer: React.FC = observer(() => {
  const powerList = useContext<PowerList>(PowerListContext);
  // const descriptionRef = useRef(null);
  // const scrollToBottom = () => {
  //   if (descriptionRef.current) {
  //     const scrollableElement = descriptionRef.current;
  //     scrollableElement.scrollTop = scrollableElement.scrollHeight;
  //   }
  // };

  useEffect(() => {
    //console.log("starting power list containers");
    powerList.hydrateDataFromPage();
  }, []);

  const makeColumnsForPermalinkGrid = () => {
    if (isMobileOnly) {
      return 4;
    }
    return 8;
  };

  return (
    <>
      <div></div>
      {powerList.hasLoaded && (
        <>
          <Stack>
            <Center>
              <Title order={1}>
                <IconBolt
                  style={{ verticalAlign: "middle", marginBottom: "5px" }}
                  size="3rem"
                  stroke={2}
                />{" "}
                {powerList.powerListDataFrozen?.data.title}{" "}
                <IconBolt
                  style={{ verticalAlign: "middle", marginBottom: "5px" }}
                  size="3rem"
                  stroke={2}
                />{" "}
              </Title>
            </Center>
            <Center>
              <Title c="dimmed" order={5}>
                The top players on the Bitcoin Lightning Network
              </Title>
            </Center>

            <PowerListTable powerList={powerList} />
            <PowerListStats powerList={powerList} />

            <Title id="ranking-criteria" order={4}>
              Ranking criteria
            </Title>
            <Text size="md">
              The Lightning Network is a confusing place. Nodes are constantly
              appearing and disappearing, and many entities run multiple nodes.
              At the same time, many node operators are pseudonymous: While
              their nodes are publicly "announced", in practice the identity of
              the node-runner is completely unknown. This is actually a feature,
              not a bug.{" "}
            </Text>
            <Text size="md">
              The Lightning Network was designed for privacy, and with many
              participants operating from jurisdictions with limited freedom of
              speech, or in places where financial freedom is discouraged, it's
              critical that those entities are able to work on a pseudonymous
              basis.{" "}
            </Text>
            <Text size="md">
              For this reason, and to establish a relatively comprehensible
              ranking, we've chosen to include only entities who are making an
              attempt to identify themselves. This means that we're only
              considering participants who either have a publicly known
              identity, or who have made some attempt to publicly identify
              themselves - for example, by creating a website.
            </Text>
            <Text size="md">
              This ranking is strictly ordered by 'size', also called
              'capacity', that is, the quantity of Bitcoin that each entity has
              deployed onto the network. On the Lightning Network, these amounts
              are publicly announced, but the destination and frequency of
              actual payments are confidential. This means that some of these
              nodes might have substantial funds allocated to their channels but
              experience minimal transaction activity.
            </Text>
            <Divider />
            <Text size="sm"> permalinks: </Text>
            <SimpleGrid cols={makeColumnsForPermalinkGrid()} spacing="xs">
              {[...powerList.powerListDataFrozen.data.squires]
                .sort((a, b) => a.Name.localeCompare(b.Name))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <Anchor
                      href={makeLinkFromSquireName(
                        item.Name,
                        powerList.powerListDataFrozen?.data.base_url
                      )}
                    >
                      <Text size="xs" span>
                        {item.Name}
                      </Text>
                    </Anchor>
                  </React.Fragment>
                ))}
            </SimpleGrid>
          </Stack>
        </>
      )}
    </>
  );
});

export default PowerListContainer;
