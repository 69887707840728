import "@mantine/core/styles.layer.css";
import "mantine-datatable/styles.layer.css";
import "@mantine/notifications/styles.layer.css";
import React from "react";
import { createRoot } from "react-dom/client";
import AppContainer from "./AppContainer";
import * as Sentry from "@sentry/react";

export const startProject = () => {
  // const point: Point = { x: 1, y: 2 };

  // logPoint(point);
  console.log("is js heavy");
};

if (window.location.hostname === "megalithic.me") {
  Sentry.init({
    dsn: "https://d8a13bdbc7167f96e23511c4bc091121@o127869.ingest.us.sentry.io/4507302560792576",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Send a test error to Sentry after page load
  // window.addEventListener("load", () => {
  //   Sentry.captureException(new Error("This is a test error sent to Sentry."));
  // });
}

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(<AppContainer />);
});
