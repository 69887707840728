import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
  Image,
  Tooltip,
} from "@mantine/core";

import { DataTable } from "mantine-datatable";
import { isMobileOnly } from "react-device-detect";
import { ExplorerWorkspace } from "../stores/ExplorerWorkspace";

import classes from "./ExplorerTable.module.css";
import { IconCoins, IconGift } from "@tabler/icons-react";

const ExplorerTable = observer(
  ({ explorerWorkspace }: { explorerWorkspace: ExplorerWorkspace }) => {
    const searchResults = explorerWorkspace.enhancedAssets;
    useEffect(() => {}, []);

    const columns = [
      {
        title: "Preview",
        accessor: "modifiedImageUrlData",
        width: 100,
        render: ({ modifiedImageUrlData }) => (
          <>
            <div
              style={{
                width: 80, // Width of the container
                height: 80, // Fixed height for all images
                overflow: "hidden", // Hide overflow to crop images
                display: "flex", // Use flex to align items
                alignItems: "center", // Center-align images vertically
                justifyContent: "center", // Center-align images horizontally
              }}
            >
              <img
                src={modifiedImageUrlData}
                style={{
                  width: "auto", // Auto width to maintain aspect ratio
                  minHeight: "100%", // Minimum height to fill the container
                  maxHeight: "100%", // Maximum height to fit the container
                }}
              />
            </div>
          </>
        ),
      },

      { title: "Name", width: 250, accessor: "truncatedAssetName" },
      !isMobileOnly && {
        title: "Supply",
        width: 70,
        accessor: "formattedSupply",
      },
      !isMobileOnly && {
        title: "Type",
        width: 60,
        accessor: "asset_type",
        render: ({ asset_type }) => (
          <>
            {asset_type == "NORMAL" && (
              <Tooltip label={"Normal asset"} color="grey">
                <IconCoins
                  className={classes.icon}
                  size="1.4rem"
                  stroke={1.5}
                />
              </Tooltip>
            )}

            {asset_type == "COLLECTIBLE" && (
              <Tooltip label={"Collectible asset"} color="grey">
                <IconGift className={classes.icon} size="1.4rem" stroke={1.5} />
              </Tooltip>
            )}
          </>
        ),
      },
      !isMobileOnly && {
        title: "Original",
        accessor: "isOriginal",
        width: 80,
      },
      !isMobileOnly && {
        title: "Genesis",
        accessor: "assetGenesisDate",
        width: 150,
      },
    ].filter(Boolean);

    return (
      <>
        {" "}
        <DataTable
          // height={900}
          withTableBorder
          borderRadius="sm"
          withColumnBorders
          //className={classes.table}
          striped
          highlightOnHover
          idAccessor="asset_universe_id"
          records={searchResults}
          onRowClick={(recordEvent) => {
            console.log(recordEvent.record);
            explorerWorkspace.makeANewSearch(
              recordEvent.record.asset_universe_id
            );
          }}
          // rowExpansion={{
          //   initiallyExpanded: () => false,

          //   content: ({ record }) => <AssetDetail asset={record} />,
          // }}
          columns={columns}
        />
      </>
    );
  }
);

export default ExplorerTable;
