import { format } from "d3-format";
import { formatDistanceToNow } from "date-fns";
import numeral from "numeral";

export const formatPriceForMillions = (price: number) => {
  return "$" + format(".2s")(price).toLowerCase();
};

export const formatBitcoinPrice = (btcValue: number, btcInDollars: number) => {
  const dollarAmount = btcValue * btcInDollars;
  const formattedNumber =
    "$" + numeral(dollarAmount).format("0.0a").replace("m", " Million");
  return formattedNumber;
};

export const formatSatoshis = (sats: number) => {
  const formattedSats = Math.floor(sats).toLocaleString();
  return formattedSats + " satoshis";
};

export const convertSatoshisToUsd = (sats: number, btcInDollars: number) => {
  const dollarAmount = (sats * btcInDollars) / 100000000;
  const formattedPrice = format("$.2f")(dollarAmount);
  return formattedPrice;
};

export const formatUSDAmount = (usdAmount: number) => {
  return format(".0f")(usdAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dateTimeToTimeAgo = (dateTime: string) => {
  return formatDistanceToNow(new Date(dateTime), { addSuffix: true });
};

export const formatNumberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatSupply = (supply: number) => {
  if (supply == 1.0) {
    return "1";
  } else {
    return format(".2s")(supply);
  }
};

export const convertKBtoGB = (kb) => parseFloat((kb / 1048576).toFixed(2));
