import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
  Image,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { DataTable } from "mantine-datatable";
import { isTablet, isMobile, isMobileOnly } from "react-device-detect";
import { AssetWorkspace } from "../stores/AssetWorkspace";
import { Chip } from "@mantine/core";
import classes from "./AssetTable.module.css";
import { IconCopy, IconX } from "@tabler/icons-react";
import { format } from "d3-format";
import AssetDetail from "./AssetDetail";

const AssetTable = observer(
  ({ assetWorkspace }: { assetWorkspace: AssetWorkspace }) => {
    const offers = assetWorkspace.enhancedOffers;
    useEffect(() => {}, []);

    // {
    //     "assetName": "MegalithGenesisCoin",
    //     "imageUrlData": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAMAAABrrFhUAAAC/VBMVEUAAACjoqO5ubmwsLCQkJCWlpZ7e3ucnJzT09PY2NjExMSJiYmwsLBjY2PGxsaPj4/o6Ojd3d2mpqbx/f5iYmJsbGxQaq0xSJKCgoM2SZNZWVlCYqVycnN7e3srSJJRUVGLiotwQYorXJ1PX6A+XaI8RpApUp45Vp5QRYuydKdSc7YlTZp7SJtRTJIlRJBnRY2CTKFcR4yJpt52l9NKZak8T5hcgMMgQY38gaFISEhKbrKQU6pEUpv9iqKJT6bOZJ/hZKHBZJ+TkpRCRYucWLaWVa+9dKczTpn6cqm6fqtYaqzrZaFxQ5tESpGurq+1l7W1Zp00itOjW7v2aqJjh8hjb7EvZqNEaKzXY6B4RJC2tbZUVZipqamPquOrXr5qdbiabKpYYZttN3qbmptVZKY9LYT9laandqm9vb1NX5NYebz8oqXKdKmwXqmLRJFAXJNMVZGaRI9wfsFiNYnAlbFdOHvtcqpjjtNui8zJmbJSNpJeldxFMYz7csyXUqGBn9",
    //     "assetTextData": "The first asset created by Megalithic.me",
    //     "genesisTransaction": "77f867378b1879b7cb4d0b272ab95a66c9b0f76e03fcf31e5269f9beba0344b6:1",
    //     "genesisTimestamp": 1713804747,
    //     "assetId": "5eb3e9332e8b0d12d4831e26dd9c6553c2467b9a6927e7006953b33987ddcf1e",
    //     "assetType": "NORMAL",
    //     "editorialDescription": null,
    //     "supply": 100,
    //     "isBuyOrSell": "is_sell",
    //     "offerQuantity": 1,
    //     "offerPriceInSatoshis": 100000
    // }

    //     {
    //     "assetName": "Bitcoin Wizard",
    //     "imageUrlData": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/4RDaRXhpZgAATU0AKgAAAAgABAE7AAIAAAAFAAA-",
    //     "assetTextData": null,
    //     "genesisTransaction": "224c73d36ca2fe87b18d8e0e792f8da46ea48400a2eb77dca4cf70b5f48cec92:1",
    //     "genesisTimestamp": 1697793814,
    //     "assetId": "ca3e585b7353bd1026e9184e6cbb5103b0a9887ddfd1c2d140e99113047a393d",
    //     "assetType": "COLLECTIBLE",
    //     "editorialDescription": null,
    //     "supply": 1,
    //     "isBuyOrSell": "is_buy",
    //     "offerQuantity": 1,
    //     "offerPriceInSatoshis": 50000
    // }

    const buttonColor = (action: string) => {
      if (action == "Buy") {
        return "teal";
      }
      return "blue";
    };

    const columns = [
      {
        title: "Preview",
        accessor: "modifiedImageUrlData",
        width: 100,
        render: ({ modifiedImageUrlData }) => (
          <>
            <div
              style={{
                width: 80, // Width of the container
                height: 80, // Fixed height for all images
                overflow: "hidden", // Hide overflow to crop images
                display: "flex", // Use flex to align items
                alignItems: "center", // Center-align images vertically
                justifyContent: "center", // Center-align images horizontally
              }}
            >
              <img
                src={modifiedImageUrlData}
                style={{
                  width: "auto", // Auto width to maintain aspect ratio
                  minHeight: "100%", // Minimum height to fill the container
                  maxHeight: "100%", // Maximum height to fit the container
                }}
              />
            </div>
          </>
        ),
      },

      { title: "Name", width: 200, accessor: "assetName" },
      !isMobileOnly && {
        title: "Supply",
        width: 100,
        accessor: "formattedSupply",
      },
      !isMobileOnly && { title: "Price", width: 200, accessor: "price" },
      !isMobileOnly && {
        title: "Action",
        accessor: "action",
        render: ({ action }) => (
          <Button color={buttonColor(action)} variant="filled" size="xs">
            {action}
          </Button>
        ),
      },
      !isMobileOnly && { title: "Note", accessor: "note" },
    ].filter(Boolean);

    return (
      <>
        {" "}
        <DataTable
          // height={900}
          withTableBorder
          borderRadius="sm"
          withColumnBorders
          //className={classes.table}
          striped
          highlightOnHover
          idAccessor="assetId"
          // rowClassName={({ Name }) =>
          //   Name === navigatedName() ? classes.redRow : undefined
          // }
          // customRowAttributes={({ Name }) => ({
          //   "data-row-squire-name": sanitizeSquireName(Name),
          // })}
          records={offers}
          rowExpansion={{
            // initiallyExpanded: ({ record: { Name } }) =>
            //   Name === navigatedName(),
            initiallyExpanded: () => false,

            content: ({ record }) => <AssetDetail asset={record} />,
          }}
          columns={columns}
        />
      </>
    );
  }
);

export default AssetTable;
