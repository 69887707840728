import React, { useState } from "react";
import {
  Container,
  Group,
  Burger,
  Anchor,
  Drawer,
  Image,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import classes from "./HeaderSimple.module.css";
import LogoAnchor from "./Logo";

// console.log(classes);

const links = [
  { link: "https://docs.megalithic.me", label: "Docs" },
  { link: "/taproot_assets", label: "Exchange" },
  { link: "/taproot_assets_explorer", label: "Explorer" },
  { link: "/power_list", label: "Power List" },
  { link: "/", label: "Node" },
  { link: "/contact", label: "Contact" },
];

export function HeaderSimple() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [active, setActive] = useState(window.location.pathname);

  const handleItemClick = (link) => {
    setActive(link.link);
    window.location.href = link.link; // Navigate to the clicked URL
  };

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.link}
      data-active={active === link.link || undefined}
      onClick={(event) => {
        event.preventDefault();
        handleItemClick(link);
        closeDrawer(); // Close the drawer when an item is clicked
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <div>
      <header className={classes.header}>
        <Container size="md" className={classes.inner}>
          <LogoAnchor showImage={true} />
          <Group gap={5} visibleFrom="md">
            {items}
          </Group>
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="md"
            size="sm"
          />
        </Container>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="md"
        padding="md"
        title="megalithic.me"
        zIndex={1000000}
        style={{ width: "100%" }}
      >
        {items}
      </Drawer>
    </div>
  );
}
