import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  Box,
  Button,
  Stack,
  Paper,
  Group,
  Title,
  SimpleGrid,
  Text,
  Loader, // Import a loader component for fallback
} from "@mantine/core";
import { observer } from "mobx-react";
import {
  IconArrowNarrowLeft,
  IconCopy,
  IconChecklist,
  IconCalendarEvent,
  IconCoins,
  IconGift,
} from "@tabler/icons-react";
import { CopyButton } from "@mantine/core";
import { ExplorerWorkspace } from "../stores/ExplorerWorkspace";
import ExplorerValidateAsset from "./ExplorerValidateAsset";
import LnFi from "./one_asset/LnFi";
import NostrLoginButton from "./partials/NostrLoginButton";
import AssetDetails from "./AssetDetails"; // Import the new component
import classes from "./PowerListStats.module.css";

const ExplorerBlocks = lazy(() => import("./ExplorerBlocks"));

const ExplorerOneAsset = observer(
  ({ explorerWorkspace }: { explorerWorkspace: ExplorerWorkspace }) => {
    const searchResults = explorerWorkspace.enhancedAssets;
    useEffect(() => {}, []);
    const asset = searchResults[0];

    const [
      shouldShowExplorerValidateAsset,
      setShouldShowExplorerValidateAsset,
    ] = useState(false);

    return (
      <>
        <Stack>
          {explorerWorkspace.previousSearch && (
            <Box maw="md">
              <Button
                leftSection={<IconArrowNarrowLeft />}
                onClick={() => explorerWorkspace.restorePreviousSearch()}
              >
                back
              </Button>
            </Box>
          )}
          <Paper withBorder p="xl">
            <Stack>
              {explorerWorkspace.thisPageRequiresNostrLogic && (
                <NostrLoginButton explorerWorkspace={explorerWorkspace} />
              )}
              <Title order={2}> {asset.asset_name}</Title>
              <div
                style={{
                  height: 240, // Fixed height for all images
                  overflow: "hidden", // Hide overflow to crop images
                  display: "flex", // Use flex to align items
                  alignItems: "center", // Center-align images vertically
                  justifyContent: "left",
                }}
              >
                <img
                  src={asset.modifiedImageUrlData}
                  style={{
                    width: "auto", // Auto width to maintain aspect ratio
                    minHeight: "100%", // Minimum height to fill the container
                    maxHeight: "100%", // Maximum height to fit the container
                  }}
                />
              </div>
              {asset.nostr_directive && (
                <LnFi explorerWorkspace={explorerWorkspace} />
              )}
              <AssetDetails
                asset={asset}
                explorerWorkspace={explorerWorkspace}
              />{" "}
              {/* Use the new component */}
              <Group justify="left">
                <CopyButton value={explorerWorkspace.linkToCurrentSearch}>
                  {({ copied, copy }) => (
                    <Button
                      leftSection={<IconCopy />}
                      color={copied ? "teal" : "cyan"}
                      onClick={copy}
                    >
                      {copied ? "Copied!" : "Copy link"}
                    </Button>
                  )}
                </CopyButton>

                <Button
                  leftSection={<IconChecklist />}
                  onClick={() => {
                    setShouldShowExplorerValidateAsset(
                      !shouldShowExplorerValidateAsset
                    );
                  }}
                >
                  Validate My Ownership
                </Button>
              </Group>
              {shouldShowExplorerValidateAsset && (
                <ExplorerValidateAsset explorerWorkspace={explorerWorkspace} />
              )}
            </Stack>
          </Paper>

          <SimpleGrid cols={{ base: 2, xs: 3, md: 5 }}>
            <Paper withBorder p="md" radius="md">
              <Group justify="space-between">
                <Text size="xs" c="dimmed" className={classes.title}>
                  Genesis Date
                </Text>
                <IconCalendarEvent
                  className={classes.icon}
                  size="1.4rem"
                  stroke={1.5}
                />
              </Group>

              <Group align="flex-end" gap="xs" mt={25}>
                <Text className={classes.value}>{asset.assetGenesisDate}</Text>
              </Group>
              <Text fz="xs" c="dimmed" mt={7}>
                {asset.assetGenesisTime} (UTC)
              </Text>
            </Paper>
            <Paper withBorder p="md" radius="md">
              <Group justify="space-between">
                <Text size="xs" c="dimmed" className={classes.title}>
                  Asset Type
                </Text>
                {asset.asset_type === "COLLECTIBLE" && (
                  <IconGift
                    className={classes.icon}
                    size="1.4rem"
                    stroke={1.5}
                  />
                )}
                {asset.asset_type === "NORMAL" && (
                  <IconCoins
                    className={classes.icon}
                    size="1.4rem"
                    stroke={1.5}
                  />
                )}
              </Group>

              <Group align="flex-end" gap="xs" mt={25}>
                <Text className={classes.textValueSmaller}>
                  {asset.asset_type}
                </Text>
              </Group>
            </Paper>
          </SimpleGrid>

          <Suspense fallback={<Loader />}>
            {asset.genesis_context && asset.genesis_context.length > 0 && (
              <ExplorerBlocks
                data={asset.genesis_context}
                explorerWorkspace={explorerWorkspace}
              />
            )}
          </Suspense>
        </Stack>
      </>
    );
  }
);

export default ExplorerOneAsset;
