export const updateSearchQueryParam = (newSearchValue) => {
  // Create a URL object based on the current document location
  const currentUrl = new URL(window.location);

  // Access the URL's search parameters
  const params = currentUrl.searchParams;

  // Get the current value of the 'search' parameter
  const currentSearchValue = params.get("search");

  // Check if the current search value is different from the new search value
  if (currentSearchValue !== newSearchValue) {
    // Set the 'search' parameter to the new value
    params.set("search", newSearchValue);

    // Update the browser's address bar without reloading the page
    window.history.pushState(
      { search: newSearchValue },
      "",
      currentUrl.toString()
    );
  }
};

// Listen for popstate event to handle browser navigation
window.addEventListener("popstate", (event) => {
  window.location.reload();
});
