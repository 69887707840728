import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  ExplorerWorkspace,
  ExplorerWorkspaceContext,
} from "./stores/ExplorerWorkspace";

import { startExplorerActionCable } from "./helpers/actioncable";
import {
  Center,
  Code,
  Stack,
  Title,
  Text,
  List,
  Anchor,
  Paper,
  TextInput,
  Loader,
  CloseButton,
  Divider,
} from "@mantine/core";
import AssetTable from "./components/AssetTable";
import ExplorerTable from "./components/ExplorerTable";
import ExplorerOneAsset from "./components/ExplorerOneAsset";
import AssetStats from "./components/AssetStats";
import SampleSearches from "./components/partials/SampleSearches";
import KnownUniverses from "./components/partials/KnownUniverses";

const ExplorerWorkspaceContainer: React.FC = observer(() => {
  const explorerWorkspace = useContext<ExplorerWorkspace>(
    ExplorerWorkspaceContext
  );

  useEffect(() => {
    //console.log("starting power list containers");
    explorerWorkspace.hydrateDataFromPage();
    //console.log(explorerWorkspace.explorerWorkspaceDataFrozen.data);
    console.log(
      "explorer workspace exists with",
      explorerWorkspace.workspaceUuid
    );
    startExplorerActionCable(explorerWorkspace);
  }, []);
  console.log("value of search is loading", explorerWorkspace.searchIsLoading);

  return (
    <>
      {explorerWorkspace.hasLoaded && (
        <>
          <Center>
            <Title order={1}>The Taproot Assets Explorer</Title>
          </Center>
          <Paper withBorder mb="md" p="md">
            <TextInput
              placeholder="search all taproot assets"
              value={explorerWorkspace.searchBoxValue}
              onChange={(event) =>
                explorerWorkspace.updateSearch(event.currentTarget.value)
              }
              rightSection={
                explorerWorkspace.searchIsLoading ? (
                  <Loader size="1rem" />
                ) : (
                  explorerWorkspace.shouldShowXButton && (
                    <CloseButton
                      onClick={() => explorerWorkspace.clearSearch()}
                    />
                  )
                )
              }
            />
          </Paper>
          <Paper mb="xl">
            {!explorerWorkspace.searchIsLoading && (
              <>
                {explorerWorkspace.searchResultsFrozen.data.length == 0 && (
                  <SampleSearches explorerWorkspace={explorerWorkspace} />
                )}

                {explorerWorkspace.searchResultsFrozen.data.length > 1 && (
                  <ExplorerTable explorerWorkspace={explorerWorkspace} />
                )}
                {explorerWorkspace.searchResultsFrozen.data.length == 1 && (
                  <>
                    <ExplorerOneAsset explorerWorkspace={explorerWorkspace} />
                  </>
                )}
              </>
            )}
            {explorerWorkspace.searchIsLoading && (
              <Center>
                <Loader size="lg" />
              </Center>
            )}
          </Paper>
          {!explorerWorkspace.searchIsLoading &&
            explorerWorkspace.searchResultsFrozen.data.length !== 1 && (
              <>
                <Paper>
                  <Stack>
                    <Center>
                      <Text>data from known universes </Text>
                    </Center>
                    <AssetStats
                      assetData={
                        explorerWorkspace.explorerWorkspaceDataFrozen.data
                          .single_setting_assets_data
                      }
                    />
                    <Center>
                      <Text>known universes </Text>
                    </Center>
                    <KnownUniverses />
                    <Text>
                      Are you running a universe server? Please{" "}
                      <Anchor href="/contact">contact us</Anchor> so we can list
                      it.
                    </Text>
                  </Stack>
                </Paper>
              </>
            )}
        </>
      )}
    </>
  );
});

export default ExplorerWorkspaceContainer;
