import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
  Paper,
  SimpleGrid,
} from "@mantine/core";

import {
  IconCoinBitcoin,
  IconCoin,
  IconBolt,
  IconExchange,
  IconCircuitChangeover,
  IconClockHour10,
} from "@tabler/icons-react";
import classes from "./PowerListStats.module.css";

import { PowerList } from "../stores/PowerList";
import {
  dateTimeToTimeAgo,
  formatUSDAmount,
  formatBitcoinPrice,
  formatNumberWithCommas,
} from "../helpers/numbers";

const PowerListStats = observer(({ powerList }: { powerList: PowerList }) => {
  return (
    <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }}>
      <Paper withBorder p="md" radius="md">
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            BTC/USD Price
          </Text>
          <IconCoin className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            $
            {formatUSDAmount(
              powerList.powerListDataFrozen.data.single_setting.btc_usd_price
            )}
          </Text>
          {/* <Text
            c={stat.diff > 0 ? "teal" : "red"}
            fz="sm"
            fw={500}
            className={classes.diff}
          >
            <span>{stat.diff}%</span>
            <DiffIcon size="1rem" stroke={1.5} />
          </Text> */}
        </Group>
        <Text fz="xs" c="dimmed" mt={7}>
          1 BTC
        </Text>
      </Paper>

      <Paper withBorder p="md" radius="md">
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            Network Size
          </Text>
          <IconCoinBitcoin
            className={classes.icon}
            size="1.4rem"
            stroke={1.5}
          />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {formatBitcoinPrice(
              powerList.powerListDataFrozen.data.single_setting.btc_usd_price,
              powerList.powerListDataFrozen.data.single_setting
                .total_btc_size_of_network
            )}
          </Text>
        </Group>
        <Text fz="xs" c="dimmed" mt={7}>
          {formatNumberWithCommas(
            powerList.powerListDataFrozen.data.single_setting
              .total_btc_size_of_network
          )}{" "}
          BTC
        </Text>
      </Paper>
      <Paper withBorder p="md" radius="md">
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            Power List Size
          </Text>
          <IconBolt className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {formatBitcoinPrice(
              powerList.powerListDataFrozen.data.single_setting.btc_usd_price,
              powerList.powerListDataFrozen.data.single_setting
                .total_btc_size_of_power_list
            )}
          </Text>
        </Group>
        <Text fz="xs" c="dimmed" mt={7}>
          {formatNumberWithCommas(
            powerList.powerListDataFrozen.data.single_setting
              .total_btc_size_of_power_list
          )}{" "}
          BTC
        </Text>
      </Paper>
      <Paper withBorder p="md" radius="md">
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            Network Channels
          </Text>
          <IconExchange className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {formatNumberWithCommas(
              powerList.powerListDataFrozen.data.single_setting
                .total_num_channels_of_network
            )}
          </Text>
        </Group>
        <Text fz="xs" c="dimmed" mt={7}>
          {
            powerList.powerListDataFrozen.data.single_setting
              .edges_made_in_last_24_hours
          }{" "}
          created today
        </Text>

        {/* -- add_column(:single_settings, :edges_made_in_last_24_hours, :integer)
   -> 0.0108s
-- add_column(:single_settings, :power_list_edges_made_in_last_24_hours, :integer) */}
      </Paper>
      <Paper withBorder p="md" radius="md">
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            Power List Channels
          </Text>
          <IconCircuitChangeover
            className={classes.icon}
            size="1.4rem"
            stroke={1.5}
          />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {formatNumberWithCommas(
              powerList.powerListDataFrozen.data.single_setting
                .total_num_channels_of_power_list
            )}
          </Text>
        </Group>
        <Text fz="xs" c="dimmed" mt={7}>
          {
            powerList.powerListDataFrozen.data.single_setting
              .power_list_edges_made_in_last_24_hours
          }{" "}
          created today
        </Text>
      </Paper>
      <Paper withBorder p="md" radius="md">
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            Last Update
          </Text>
          <IconClockHour10
            className={classes.icon}
            size="1.4rem"
            stroke={1.5}
          />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {dateTimeToTimeAgo(
              powerList.powerListDataFrozen.data.single_setting.last_update
            )}
          </Text>
        </Group>
      </Paper>
    </SimpleGrid>
  );
});

export default PowerListStats;

// 1. btc_usd_price: "67954.09"
// 3. last_gossip_import: "2024-03-05T14:26:51.948Z"

// 5. total_btc_size_of_power_list: 3566
// 6. total_num_channels_of_network: 62754
// 7. total_num_channels_of_power_list: 28484
