import React from "react";
import { Textarea } from "@mantine/core";

const CustomTextarea = ({ label, value, maw }) => {
  return (
    <Textarea
      autosize
      radius="md"
      mt="8"
      mb="8"
      maw={maw}
      readOnly
      label={label}
      value={value}
      onFocus={(e) => e.currentTarget.select()}
    />
  );
};

export default CustomTextarea;
