import React from "react";
import { Text, Title, Paper, Anchor, Stack } from "@mantine/core";

const PrivacyAndTermsContainer: React.FC = () => {
  return (
    <Paper>
      <Stack>
        <Title order={2}>Privacy Policy for Megalithic.me</Title>

        <Text fw={500} size="lg">
          1. Information We Collect:
        </Text>

        <Text size="md">
          When you visit Megalithic.me, we may collect non-personally
          identifiable information such as your IP address, browser type,
          referring website, pages visited, and the time and date of each visit.
          This information helps us analyze trends, administer the website, and
          improve user experience.
        </Text>

        <Text size="md">
          We do not collect any personally identifiable information (PII) such
          as names, email addresses, or phone numbers, unless voluntarily
          provided by you. For example, if you contact us through our website's
          contact form or sign up for our newsletter, we may collect your email
          address.
        </Text>

        <Text fw={500} size="lg">
          2. How We Use Your Information:
        </Text>

        <Text size="md">
          We use non-personally identifiable information to analyze website
          usage patterns, identify popular content, and optimize our website's
          performance. If you provide us with your email address, we may use it
          to respond to your inquiries, send newsletters or updates about our
          services, or communicate with you regarding your account.
        </Text>

        <Text fw={500} size="lg">
          3. Data Security:
        </Text>

        <Text size="md">
          We take reasonable measures to secure and encrypt all data collected
          on Megalithic.me to prevent unauthorized access, disclosure,
          alteration, or destruction of your personal information.
        </Text>

        <Text fw={500} size="lg">
          4. Cookies and Tracking:
        </Text>

        <Text size="md">
          We use cookies on Megalithic.me for the sole purpose of tracking
          non-personally identifiable information. We do not track, collect, or
          store any personally identifiable information (PII) through cookies or
          other persistent identifiers.
        </Text>

        <Text fw={500} size="lg">
          6. Your Rights and Choices:
        </Text>

        <Text size="md">
          You have the right to access, correct, or delete any personal
          information we hold about you. You can contact us at any time to
          request access to your data or make changes to it. If you no longer
          wish to receive communications from us, you can opt-out by
          unsubscribing from our newsletter or contacting us directly.
        </Text>

        <Text fw={500} size="lg">
          7. Data Ownership and Sharing:
        </Text>

        <Text size="md">
          You retain full ownership and control of all data collected on
          Megalithic.me. You can request to delete your data from our servers at
          any time. We do not share, sell, or send your personal information to
          any third parties. Your data is not monetized, mined, or harvested for
          personal or behavioral trends.
        </Text>

        <Text fw={500} size="lg">
          8. Compliance:
        </Text>

        <Text size="md">
          We comply with the General Data Protection Regulation (GDPR) and the
          Privacy and Electronic Communications Regulations (PECR) by minimizing
          data collection, obtaining consent when necessary, and protecting user
          privacy rights.
        </Text>

        <Text fw={500} size="lg">
          9. Changes to this Privacy Policy:
        </Text>

        <Text size="md">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We encourage you to review
          this Privacy Policy periodically for any updates.
        </Text>

        <Text fw={500} size="lg">
          10. Contact Us:
        </Text>

        <Text size="md">
          If you have any questions, concerns, or feedback about this Privacy
          Policy or our privacy practices, please contact us at{" "}
          <Anchor href="mailto:info@megalithic.me">info@megalithic.me</Anchor>.
        </Text>

        <Text size="md">
          By using Megalithic.me, you acknowledge that you have read,
          understood, and agreed to the terms of this Privacy Policy.
        </Text>
        <Title order={2}>Terms of Service For Megalithic.me</Title>

        <Text fw={500} size="lg">
          1. Acceptance of Terms
        </Text>

        <Text size="md">
          By accessing or using our website and Bitcoin Lightning services, you
          agree to comply with these Terms of Service. If you do not agree with
          any part of these terms, you may not access or use our services.
        </Text>

        <Text fw={500} size="lg">
          2. Bitcoin Lightning Services
        </Text>

        <Text size="md">
          Our website provides Bitcoin Lightning services for your convenience.
          Usage of these services is entirely at your own risk. We disclaim any
          warranty, express or implied, regarding the reliability, availability,
          or security of these services.
        </Text>

        <Text fw={500} size="lg">
          3. Content Disclaimer
        </Text>

        <Text size="md">
          The content provided on our website is for informational purposes
          only. Nothing on this website constitutes investment advice or
          solicitation to buy or sell any securities or financial instruments.
        </Text>

        <Text fw={500} size="lg">
          4. Prohibited Activities
        </Text>

        <Text size="md">
          Automated scraping or harvesting of data from our website is strictly
          prohibited. Any unauthorized use of automated tools or software to
          extract information from our website is a violation of these Terms of
          Service.
        </Text>

        <Text fw={500} size="lg">
          5. Limitation of Liability
        </Text>

        <Text size="md">
          We shall not be liable for any damages, including but not limited to
          direct, indirect, incidental, special, or consequential damages,
          arising out of the use or inability to use our website or Bitcoin
          Lightning services.
        </Text>
        <Text size="md">
          BY ACCESSING OUR WEBSITE AND USING OUR SERVICES, YOU ACKNOWLEDGE AND
          AGREE THAT WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING
          BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA
          OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR THE
          INABILITY TO USE OUR SERVICES; (II) THE COST OF PROCUREMENT OF
          SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
          INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
          TRANSACTIONS ENTERED INTO THROUGH OR FROM OUR SERVICES; (III)
          UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
          (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR SERVICES; (V)
          YOUR RELIANCE ON CONTENT MADE AVAILABLE BY US; OR (VI) ANY OTHER
          MATTER RELATING TO OUR SERVICES.
        </Text>

        <Text fw={500} size="lg">
          6. Changes to Terms
        </Text>

        <Text size="md">
          We reserve the right to modify or replace these Terms of Service at
          any time. It is your responsibility to check this page periodically
          for changes. Your continued use of our website and services
          constitutes acceptance of the updated terms.
        </Text>
        <Text fw={500} size="lg">
          7. Contact Us:
        </Text>

        <Text size="md">
          If you have any questions, concerns, or feedback about these terms of
          service, please contact us at{" "}
          <Anchor href="mailto:info@megalithic.me">info@megalithic.me</Anchor>.
        </Text>

        <Text size="md">
          By using Megalithic.me, you acknowledge that you have read,
          understood, and agreed to these terms of service.
        </Text>
      </Stack>
    </Paper>
  );
};

export default PrivacyAndTermsContainer;
