"use client";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { DataTable } from "mantine-datatable";
import { isTablet, isMobile, isMobileOnly } from "react-device-detect";
import { PowerList } from "../stores/PowerList";
import { Chip } from "@mantine/core";
import classes from "./PowerListTable.module.css";
import { IconCopy, IconX } from "@tabler/icons-react";
import KindTags from "./KindTags";
import Flag from "./Flag";
import Markdown from "react-markdown";
import SquireSize from "./SquireSize";
import { format } from "d3-format";
import { makeLinkFromSquireName, sanitizeSquireName } from "../helpers/routes";
import SquireBtcSize from "./SquireBtcSize";

const PowerListTable = observer(({ powerList }: { powerList: PowerList }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (navigatedName()) {
        const name = sanitizeSquireName(navigatedName());
        // console.log("name", name);
        const selector = `[data-row-squire-name="${name}"]`;
        // console.log("selector", selector);
        const element = document.querySelector(selector);
        // console.log("found element", element);
        if (element) {
          scrollToTopOfPage(element);
        }
      }
    }, 100); // 100 milliseconds

    return () => clearTimeout(timeout);
  }, []);

  function scrollToTopOfPage(element) {
    // Calculate the distance from the top of the page to the element
    var elementRect = element.getBoundingClientRect();
    var absoluteElementTop = elementRect.top + window.pageYOffset;

    // Calculate how far the element is from the top of the viewport
    var relativeElementTop = absoluteElementTop - window.pageYOffset;

    // Scroll the window by the difference so that the element appears at the top of the viewport
    window.scrollBy({
      top: relativeElementTop,
      left: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  }

  const navigatedName = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    // Create a URL object
    const url = new URL(currentUrl);
    // Get the search parameters
    const searchParams = url.searchParams;
    // Get the value of the "name" parameter
    const name = searchParams.get("n");
    // Decode the parameter value to handle spaces
    if (name) {
      const decodedName = name.replace(/-/g, " ");
      // console.log("decodedName", decodedName);
      return decodedName;
    }
    return null;
  };

  const makeNameWidth = () => {
    if (isMobileOnly) {
      return 90;
    }
    return 150;
  };

  const makeCountryWidth = () => {
    if (isMobileOnly) {
      return 60;
    }
    return 80;
  };

  const columns = [
    {
      accessor: "Rank",
      width: 31,
      title: "#",
    },
    { accessor: "Name", width: makeNameWidth() },
    {
      accessor: "Country",
      width: makeCountryWidth(),
      render: ({ Country }) => <Flag countryCode={Country} />,
    },
    {
      accessor: "SizeUsd",
      title: "Size",
      render: ({ Size }) => (
        <SquireSize
          numBtc={Size}
          size={
            Size *
            powerList.powerListDataFrozen.data.single_setting.btc_usd_price
          }
        />
      ),
    },
    !isMobileOnly && {
      accessor: "SizeBtc",
      title: "Size BTC",
      render: ({ Size }) => (
        <SquireBtcSize
          numBtc={Size}
          size={
            Size *
            powerList.powerListDataFrozen.data.single_setting.btc_usd_price
          }
        />
      ),
    },
    // Add "Channels" column only if not mobile
    !isMobileOnly && {
      accessor: "Channels",
      render: ({ Channels }) => format(",")(Channels),
    },
    {
      accessor: "Kind",
      render: ({ Tags }) => <KindTags tags={Tags} />,
    },
  ].filter(Boolean);
  // console.log(powerList.powerListDataFrozen.data.squires);
  return (
    <>
      {" "}
      <DataTable
        // height={900}
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        className={classes.table}
        striped
        highlightOnHover
        idAccessor="Name"
        // rowClassName={({ Name }) =>
        //   Name === navigatedName() ? classes.redRow : undefined
        // }
        customRowAttributes={({ Name }) => ({
          "data-row-squire-name": sanitizeSquireName(Name),
        })}
        records={powerList.powerListDataFrozen.data.squires}
        rowExpansion={{
          initiallyExpanded: ({ record: { Name } }) => Name === navigatedName(),
          content: ({ record }) => (
            <>
              <Stack className={classes.details} p="xs" gap={6}>
                <Markdown>{record.Description}</Markdown>
                <Group gap={6}>
                  {/* <Button>nodes</Button>{" "} */}
                  <Button component="a" href={record.Link} target="_blank">
                    Home Page
                  </Button>
                  <CopyButton
                    value={makeLinkFromSquireName(
                      record.Name,
                      powerList.powerListDataFrozen?.data.base_url
                    )}
                  >
                    {({ copied, copy }) => (
                      <Button
                        leftSection={<IconCopy />}
                        color={copied ? "teal" : "blue"}
                        onClick={copy}
                      >
                        {copied ? "Copied!" : "Copy permalink"}
                      </Button>
                    )}
                  </CopyButton>
                </Group>
              </Stack>
            </>
          ),
        }}
        columns={columns}
      />
    </>
  );
});

export default PowerListTable;
