import _, { get } from "lodash";
import React from "react";
import { computed, observable } from "mobx";
import {
  registerRootStore,
  fromSnapshot,
  frozen,
  Frozen,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  onSnapshot,
  prop,
  Ref,
  undoMiddleware,
  _async,
  _await,
  idProp,
} from "mobx-keystone";

import { createContext } from "react";

@model("megalithic/PowerList")
export class PowerList extends Model({
  id: idProp,
  hasLoaded: prop<boolean>(false),
  powerListDataFrozen: prop<Frozen<any> | undefined>(),
}) {
  @modelAction
  setHasLoaded(hasLoaded: boolean) {
    this.hasLoaded = hasLoaded;
  }
  @modelAction
  hydrateDataFromPage() {
    const rootDiv = document.getElementById("root");
    const json = JSON.parse(rootDiv.dataset.hydrate);
    // console.log("here is the data from the page", json);
    this.powerListDataFrozen = frozen(json);
    this.setHasLoaded(true);
  }
}

export const runCreatePowerList = () => {
  const powerList = new PowerList({});
  registerRootStore(powerList);
  return powerList;
};

const createdPowerList = runCreatePowerList();
//console.log("created workspace", getSnapshot(createdWorkspace));
export const PowerListContext = createContext<PowerList>(createdPowerList);

export interface PowerListInterface {
  PowerList: PowerList;
}
