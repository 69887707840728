import {
  Paper,
  Stack,
  Title,
  Anchor,
  Image,
  Text,
  List,
  Button,
  Divider,
  CopyButton,
  Textarea,
  Center,
  Box,
} from "@mantine/core";
import React, { useState } from "react";
import { formatUnixTimestamp } from "../helpers/dateAndTime";
import _ from "lodash";
import { IconBrandTelegram, IconCopy } from "@tabler/icons-react";
import { AssetProps } from "./AssetDetail";

const AssetTransaction: React.FC<AssetProps> = ({ asset }) => {
  const [showContent, setShowContent] = useState(false);

  const handleButtonClick = () => {
    setShowContent(true);
  };

  const transactionMessage = (asset) => {
    let message = "";
    if (asset.isBuyOrSell == "is_buy") {
      message +=
        "Hey Megalith, I want to sell the asset ID " +
        asset.assetId +
        ". " +
        "I want to sell " +
        asset.offerQuantity +
        " of this asset, for a total price of " +
        asset.offerPriceInSatoshis.toLocaleString() +
        " satoshis.";
    } else {
      message +=
        "Hey Megalith, I want to buy the asset ID" +
        asset.assetId +
        ". " +
        "I want to buy " +
        asset.offerQuantity +
        " of this asset, for a total price of " +
        asset.offerPriceInSatoshis.toLocaleString() +
        " satoshis.";
    }

    return message;
  };

  const ctaTransactionMessage = (asset) => {
    if (asset.isBuyOrSell == "is_buy") {
      return "Sell This Asset";
    }
    return "Buy This Asset";
  };

  const howToTransactHeadline = (asset) => {
    if (asset.isBuyOrSell == "is_buy") {
      return "How to sell this asset to Megalith";
    } else {
      return "How to buy this asset from Megalith";
    }
  };
  return (
    <>
      {!showContent && (
        <Box maw="md">
          <Button variant="filled" size="sm" onClick={handleButtonClick}>
            {ctaTransactionMessage(asset)}
          </Button>
        </Box>
      )}

      {showContent && (
        <>
          <Title mt="md" order={3}>
            {howToTransactHeadline(asset)}
          </Title>
          <List>
            <List.Item>
              Taproot assets transfers aren't yet enabled on Lightning.
            </List.Item>
            <List.Item>
              Transfers are currently possible using the main chain, at the same
              fee weight as a normal taproot transaction.
            </List.Item>
            <List.Item>
              Taproot Assets transfers are still a little complicated and
              error-prone. For this reason, we're doing all buying and selling
              manually, over Telegram.
            </List.Item>
            <List.Item>
              We don't recommend using any wallet solution except for{" "}
              <Anchor
                href="https://github.com/lightninglabs/taproot-assets"
                target="_blank"
              >
                tapd
              </Anchor>
              , running alongside{" "}
              <Anchor
                href="https://github.com/lightningnetwork/lnd"
                target="_blank"
              >
                lnd
              </Anchor>
              , running alongside{" "}
              <Anchor href="https://github.com/bitcoin/bitcoin" target="_blank">
                Bitcoin Core
              </Anchor>
              .
            </List.Item>
            <List.Item>
              To initiate a transaction with Megalith Node, copy the text below
              and send it as a message to us on Telegram.
            </List.Item>

            <Textarea
              autosize
              minRows={2}
              maxRows={8}
              radius="md"
              mt="8"
              mb="8"
              maw="97%"
              readOnly
              label="Send this message to initiate transaction"
              value={transactionMessage(asset)}
              //onFocus={(e) => e.currentTarget.select()}
            />
            <CopyButton value={transactionMessage(asset)}>
              {({ copied, copy }) => (
                <Button
                  leftSection={<IconCopy />}
                  color={copied ? "teal" : "blue"}
                  onClick={copy}
                >
                  {copied ? "Copied!" : "Copy message"}
                </Button>
              )}
            </CopyButton>
          </List>
          <Box maw="md">
            <Button
              component="a"
              href="https://t.me/megalith_node"
              leftSection={<IconBrandTelegram />}
            >
              Send Telegram Message
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default AssetTransaction;
