import { createConsumer } from "@rails/actioncable";
import { ExplorerWorkspace } from "../stores/ExplorerWorkspace";

declare global {
  interface Window {
    ExplorerChannelSubscription: any;
  }
}

export const startExplorerActionCable = (
  explorerWorkspace: ExplorerWorkspace
) => {
  const consumer = createConsumer();
  //console.log(consumer);
  console.log("--in start action cable---");
  // console.log("workspace.channelIndex", workspace.channelIndex);
  window.ExplorerChannelSubscription = consumer.subscriptions.create(
    {
      channel: "ExplorerChannel",
      workspaceUuid: explorerWorkspace.workspaceUuid,
    },

    {
      connected() {
        console.log("a cable connect");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      received(data) {
        console.log("data received from rails", data);
        console.log(data);
        if (data.message == "verify-and-decode-proof") {
          explorerWorkspace.handleVerifyAndDecodeProof(data);
        }

        // if (data.message == "question-failed") {
        //   workspace.handleQuestionFailure();
        // }
      },
    }
  );
};
