import React from "react";
import { Textarea, Text, Anchor, Group } from "@mantine/core";
import _ from "lodash";
import { ExplorerWorkspace } from "../stores/ExplorerWorkspace";

const AssetDetails = ({
  asset,
  explorerWorkspace,
}: {
  asset: any;
  explorerWorkspace: ExplorerWorkspace;
}) => {
  return (
    <>
      <Textarea
        autosize
        radius="md"
        mt="8"
        mb="8"
        maw="98%"
        readOnly
        label="Asset ID"
        value={asset.asset_universe_id}
        onFocus={(e) => e.currentTarget.select()}
      />
      <Textarea
        autosize
        radius="md"
        mt="8"
        mb="8"
        maw="98%"
        readOnly
        label="Genesis Point"
        value={asset.asset_genesis_point}
        onFocus={(e) => e.currentTarget.select()}
      />
      <Textarea
        autosize
        radius="md"
        mt="8"
        mb="8"
        maw="98%"
        readOnly
        label="Anchor Point"
        value={asset.asset_anchor_point}
        onFocus={(e) => e.currentTarget.select()}
      />

      {asset.group_key && (
        <Textarea
          autosize
          radius="md"
          mt="8"
          mb="8"
          maw="98%"
          readOnly
          label="Group Key"
          value={asset.group_key}
          onFocus={(e) => e.currentTarget.select()}
        />
      )}
      <Textarea
        autosize
        radius="md"
        mt="8"
        mb="8"
        maw="98%"
        readOnly
        label="Metadata Hash"
        value={asset.meta_hash}
        onFocus={(e) => e.currentTarget.select()}
      />

      <Textarea
        autosize
        radius="md"
        mt="8"
        mb="8"
        maw="50%"
        readOnly
        label="Supply"
        value={asset.asset_total_supply}
        onFocus={(e) => e.currentTarget.select()}
      />

      <Textarea
        autosize
        radius="md"
        mt="8"
        mb="8"
        maw="50%"
        readOnly
        label="Block Height"
        value={asset.asset_genesis_height}
        onFocus={(e) => e.currentTarget.select()}
      />
      {asset.known_text && (
        <Textarea
          autosize
          radius="md"
          mt="8"
          mb="8"
          maw="50%"
          readOnly
          label="Embedded Text"
          value={asset.known_text}
          onFocus={(e) => e.currentTarget.select()}
        />
      )}
      {asset.duplicate_of_asset_universe_id && (
        <Text>
          duplicate of{" "}
          <Anchor
            onClick={() =>
              explorerWorkspace.updateSearch(
                asset.duplicate_of_asset_universe_id
              )
            }
          >
            {_.truncate(asset.duplicate_of_asset_universe_id, {
              length: 8,
              omission: "",
            })}
          </Anchor>
        </Text>
      )}
    </>
  );
};

export default AssetDetails;
