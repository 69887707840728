import {
  Box,
  Chip,
  Group,
  Paper,
  Stack,
  Title,
  Tooltip,
  rem,
  Text,
  Button,
  TextInput,
  CopyButton,
  Divider,
} from "@mantine/core";
import { IconBrandTelegram, IconCopy } from "@tabler/icons-react";
import { format } from "d3-format";

import React from "react";

const ContactContainer: React.FC = () => {
  return (
    <Paper>
      <Stack>
        <Title order={1}>Contact The Megalith Node</Title>
        <Text span size="md">
          Please reach out for any questions, comments, or if you have a
          question about the Lightning Network.
        </Text>
        <Text span size="md">
          The best way to reach us is via Telegram.
        </Text>
        <Box maw="md">
          <Button
            component="a"
            href="https://t.me/megalith_node"
            leftSection={<IconBrandTelegram />}
          >
            Send Telegram Message To Megalith Node
          </Button>
        </Box>
        <Text>You can also reach us with email</Text>
        <TextInput
          radius="md"
          mt="8"
          mb="8"
          maw="60%"
          readOnly
          value={"info@megalithic.me"}
          onFocus={(e) => e.currentTarget.select()}
        />
        <Box maw="md">
          <CopyButton value="info@megalithic.me">
            {({ copied, copy }) => (
              <Button
                leftSection={<IconCopy />}
                color={copied ? "teal" : "blue"}
                onClick={copy}
              >
                {copied ? "Copied!" : "Copy email address"}
              </Button>
            )}
          </CopyButton>
        </Box>
        <Divider mt="lg" />
        <Title order={3}>Who started the Megalith Node?</Title>
        <Text span size="md">
          Hi. We're software developers and Bitcoiners with an interest in
          ethical financial services. We like working on interesting projects
          that can benefit everyone who uses Bitcoin. Do get in touch if you're
          looking for help with something.
        </Text>
      </Stack>
    </Paper>
  );
};

export default ContactContainer;
