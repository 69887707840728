import { observer } from "mobx-react";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Stack,
  Text,
  rem,
  Image,
  Tooltip,
  Paper,
  Textarea,
  Title,
  SimpleGrid,
  Anchor,
  Alert,
  Loader,
  Center,
} from "@mantine/core";
import _ from "lodash";

import { DataTable } from "mantine-datatable";
import { isMobileOnly } from "react-device-detect";
import { ExplorerWorkspace } from "../stores/ExplorerWorkspace";

import {
  IconArrowNarrowLeft,
  IconCalendarEvent,
  IconCoins,
  IconCopy,
  IconGift,
  IconInfoCircle,
} from "@tabler/icons-react";
import classes from "./PowerListStats.module.css";
import ShowProof from "./partials/ShowProof";

// {
//   "id": 15684,
//   "asset_id": 223,
//   "asset_metadatum_id": 21428,
//   "asset_universe_id": "5eb3e9332e8b0d12d4831e26dd9c6553c2467b9a6927e7006953b33987ddcf1e",
//   "known_text": "The first asset created by Megalithic.me",
//   "generated_image_keyword_text": null,
//   "generated_text_keyword_text": "Megalith, Genesis, Coin",
//   "asset_name": "MegalithGenesisCoin",
//   "image_uri": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAMAAABrrFhUAAAC/",
//   "created_at": "2024-05-10T04:00:07.295Z",
//   "updated_at": "2024-05-12T03:25:15.601Z",
//   "editorial_status": "editorial_approved",
//   "asset_genesis_date": "2024-04-22T16:52:27.000Z",
//   "computed_text_for_search": "The first asset created by Megalithic.me Megalith, Genesis, Coin",
//   "tsvector_searchable": "'asset':4B 'coin':10B 'creat':5B 'first':3B 'genesi':9B 'megalith':8B 'megalithgenesiscoin':1A 'megalithic.me':7B",
//   "asset_type": "NORMAL",
//   "asset_genesis_height": 840366,
//   "asset_genesis_point": "77f867378b1879b7cb4d0b272ab95a66c9b0f76e03fcf31e5269f9beba0344b6:1",
//   "asset_anchor_point": "8e4186ad5a269deff62c5c46f49e5233bcbc6e2deb5bde5f7744659c9931b2f2:0",
//   "asset_total_supply": 100,
//   "group_key": "028ca92f8044d1eecdccd373ec358c4c282eefca0d9258793fea7086c45ad8537b"
// }

const ExplorerValidateAsset = observer(
  ({ explorerWorkspace }: { explorerWorkspace: ExplorerWorkspace }) => {
    const searchResults = explorerWorkspace.enhancedAssets;
    useEffect(() => {}, []);
    const asset = searchResults[0];
    const [value, setValue] = useState("");
    const [scriptKeyValue, setScriptKeyValue] = useState("");
    const allowValidationClick = value.length > 100;
    // const cliCommandOne =
    //   `tapcli assets list | jq -r --arg asset_id ` +
    //   asset.asset_universe_id +
    //   ` '.assets[] | select(.asset_genesis.asset_id == $asset_id) | .script_key'`;
    // const cliCommandTwo =
    //   `tapcli proofs proveownership --asset_id ` +
    //   asset.asset_universe_id +
    //   ` --script_key ` +
    //   scriptKeyValue +
    //   ` -`;
    const cliCommandCombined =
      `tapcli --network mainnet proofs proveownership --asset_id ` +
      asset.asset_universe_id +
      ` --script_key $(tapcli --network mainnet assets list | jq -r --arg asset_id ` +
      asset.asset_universe_id +
      ` '.assets[] | select(.asset_genesis.asset_id == $asset_id) | .script_key')`;

    return (
      <>
        <Stack>
          <Title order={3}>Validate Ownership Of This Asset </Title>
          <Text>
            To validate ownership of this asset, please follow these steps.
          </Text>

          <Textarea
            autosize
            label="Paste this into your CLI"
            //description="You must have tapcli installed"
            readOnly
            //placeholder=
            value={cliCommandCombined}
            onFocus={(e) => e.currentTarget.select()}
          />
          <Textarea
            label="Paste proof_with_witness value here"
            //description="Input description"
            placeholder="544150500004000000000224f2b231999c6544775fde5beb2d6ebcbc33529ef4465c2cf6ef9d265aad86418e0000000004500000692a19347dc8ec426badab275c627c101dbb82f3ba170e16000000000000000000006a1edd36fa685d25acad1ed3328fcbd09909a5bd07811232078b29872d3e01900d943666db31031768d120a906fd016302000000000102f2b231999c6544775fde5beb2d6ebcbc33529ef4465c2cf6ef9d265aad86418e..."
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
          />

          {/* <Textarea
            autosize
            label="Paste this into your CLI"
            //description="You must have tapcli installed"
            readOnly
            //placeholder=
            value={cliCommandOne}
            onFocus={(e) => e.currentTarget.select()}
          />
          <Textarea
            autosize
            label="Paste CLI output here"
            //description="Input description"
            placeholder="020e6a67dc70939103b349f059af86ec6fb43cbb9b6eb9ad9c93f86619f6d8aa85"
            value={scriptKeyValue}
            onChange={(event) => setScriptKeyValue(event.currentTarget.value)}
          />

          {scriptKeyValue.length > 0 && (
            <>
              <Textarea
                autosize
                label="Paste this into your CLI"
                //description="You must have tapcli installed"
                readOnly
                //placeholder=
                value={cliCommandTwo}
                onFocus={(e) => e.currentTarget.select()}
              />
              <Textarea
                label="Paste proof_with_witness value here"
                //description="Input description"
                placeholder="544150500004000000000224f2b231999c6544775fde5beb2d6ebcbc33529ef4465c2cf6ef9d265aad86418e0000000004500000692a19347dc8ec426badab275c627c101dbb82f3ba170e16000000000000000000006a1edd36fa685d25acad1ed3328fcbd09909a5bd07811232078b29872d3e01900d943666db31031768d120a906fd016302000000000102f2b231999c6544775fde5beb2d6ebcbc33529ef4465c2cf6ef9d265aad86418e..."
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
              />
            </>
          )} */}

          <Button
            onClick={() => {
              explorerWorkspace.requestAssetValidation(value);
            }}
            disabled={!allowValidationClick}
          >
            Validate Now
          </Button>
          {explorerWorkspace.assetProofInProgress && (
            <>
              <Center>
                <Loader size="5rem" />
              </Center>
            </>
          )}

          {explorerWorkspace.assetProofStatus == "success" &&
            asset.asset_universe_id ==
              explorerWorkspace.assetProofFrozen.data.asset.asset_genesis
                .asset_id && (
              <>
                <ShowProof data={explorerWorkspace.assetProofFrozen.data} />
              </>
            )}
          {explorerWorkspace.assetProofStatus == "success" &&
            asset.asset_universe_id !==
              explorerWorkspace.assetProofFrozen.data.asset.asset_genesis
                .asset_id && (
              <>
                <Alert
                  variant="filled"
                  color="grape"
                  title="Wrong Asset Id"
                  icon={<IconInfoCircle />}
                >
                  This is a valid proof, but for the wrong asset id. This proof
                  is for asset id:{" "}
                  {
                    explorerWorkspace.assetProofFrozen.data.asset.asset_genesis
                      .asset_id
                  }
                </Alert>
              </>
            )}
          {explorerWorkspace.assetProofStatus == "failure" && (
            <>
              <Alert
                variant="filled"
                color="grape"
                title="Proof Validation Failed"
                icon={<IconInfoCircle />}
              >
                Please be sure you correctly copied the proof_with_witness value
                from the CLI output.
              </Alert>
            </>
          )}
        </Stack>
      </>
    );
  }
);

export default ExplorerValidateAsset;
