import { Box, Chip, Group, Tooltip, rem, Text } from "@mantine/core";
import { format } from "d3-format";
import { isMobileOnly } from "react-device-detect";
import React from "react";
import { formatPriceForMillions } from "../helpers/numbers";

interface MyComponentProps {
  numBtc: number;
  size: number;
}

const formatBTCAmount = (btcAmount) => {
  return format(".1f")(btcAmount);
};

const SquireBtcSize: React.FC<MyComponentProps> = ({ numBtc, size }) => {
  return "₿" + formatBTCAmount(numBtc);
};

export default SquireBtcSize;
