import { Box, Chip, Group, Tooltip, rem } from "@mantine/core";
import {
  IconCoinBitcoin,
  IconDice5,
  IconStatusChange,
  IconRouteAltLeft,
  IconWallet,
  IconBuildingSkyscraper,
  IconCpu,
  IconExchange,
} from "@tabler/icons-react";
import React from "react";
import { isTablet, isMobile, isMobileOnly } from "react-device-detect";
interface MyComponentProps {
  tags: string[];
}

// enum kind: {
//     exchange: 0,
//     altcoins: 1,
//     routing: 2,
//     sells_channels: 3,
//     wallet: 4,
//     enterprise: 5,
//   }

const makeIcon = (name) => {
  if (name == "sells_channels") {
    return <IconExchange style={{ width: rem(12), height: rem(12) }} />;
  }
  if (name == "altcoins") {
    return <IconDice5 style={{ width: rem(12), height: rem(12) }} />;
  }
  if (name == "exchange") {
    return <IconStatusChange style={{ width: rem(12), height: rem(12) }} />;
  }
  if (name == "routing") {
    return <IconRouteAltLeft style={{ width: rem(12), height: rem(12) }} />;
  }
  if (name == "wallet") {
    return <IconWallet style={{ width: rem(12), height: rem(12) }} />;
  }
  if (name == "enterprise") {
    return (
      <IconBuildingSkyscraper style={{ width: rem(12), height: rem(12) }} />
    );
  }
  if (name == "developer") {
    return <IconCpu style={{ width: rem(12), height: rem(12) }} />;
  }
  if (name == "payments") {
    return <IconCoinBitcoin style={{ width: rem(12), height: rem(12) }} />;
  }
};

const makeColor = (name) => {
  if (name == "sells_channels") {
    return "gray";
  }
  if (name == "altcoins") {
    return "#b2f2bb";
  }
  if (name == "exchange") {
    return "violet";
  }
  if (name == "routing") {
    return "blue";
  }
  if (name == "wallet") {
    return "indigo";
  }
  if (name == "enterprise") {
    return "orange";
  }
  if (name == "developer") {
    return "cyan";
  }
  if (name == "payments") {
    return "#fff9db";
  }
};

const makeDisplayName = (name) => {
  if (name == "sells_channels" && isMobileOnly) {
    return "channels";
  }
  if (name == "sells_channels") {
    return "channel sales";
  }
  return name;
};

const KindTags: React.FC<MyComponentProps> = ({ tags }) => {
  return (
    <Group>
      {tags.map((name, index) => (
        // <Tooltip
        //   label="Primarily used for gambling, altcoins can turn a quick profit if you buy early and find the right time to sell to later investors"
        //   refProp="rootRef"
        // >
        <Chip
          icon={makeIcon(name)}
          key={name}
          color={makeColor(name)}
          variant="light"
          size="xs"
          radius="sm"
          checked={true}
        >
          {makeDisplayName(name)}
        </Chip>
        // </Tooltip>
      ))}
    </Group>
  );
};

export default KindTags;
